@include block('banner-hero'){
  &.wasteking{

    &#contact-us{
      margin: 50px 0;
      @include media($bp-md){
      margin: 100px 0;
      }
      /// .banner-hero
      @include block('banner-hero'){

        /// .banner-hero__title        
        @include element('title'){
          font-size:26px;
          font-family: $brandon_bold;
          text-transform: uppercase;
          color: $c_wk_heading_text-base;
          margin-bottom: 10px;
          padding-bottom: 0;
          letter-spacing: 1px;

          // screens > 768
          @include media($bp-md){
            font-size: 48px;
            margin-bottom: 20px;
            padding-bottom: 0;
          }
        }
      /// .banner-hero__description
        @include element('description'){
          p{
            font-size: 16px;
            padding-bottom: 5px;

            @include media($bp-md){
              font-size: 20px;
            }
            a{
              text-decoration: none;
              color:$c_wk_banner-hero_link-color;
            }
          }
          
        }

      }
    }

    // targeting the specific id's
    &#videos,
    &#accessories{
      margin: 0;
      
      .banner-hero{
        /// .banner-hero__image-container
        @include element('image-container'){
          overflow: hidden;
          height: 160px;
    
          /// screens > 768
          @include media($bp-md){
            height: 325px;
          }
          /// screens > 992
          @include media($bp-lg){
            height:490px;
          }
        
        }
        /// .banner-hero__title
        @include element('title'){
          font-size: 20px;
          font-family: $brandon_bold;
          text-transform: uppercase;
          color: $c_wk_heading_text-base;
          // screens > 768
          @include media($bp-md){
            font-size: 28px;
          }
        }
        /// .banner-hero__description
        @include element('description'){
          p{
            font-size: 16px;

            @include media($bp-md){
              font-size: 20px;
            }
          }
        }
      }
    }

    &#accessories{
      .banner-hero{
        /// .banner-hero__content
        @include element('content'){
          margin: 40px auto 20px;
          @include media($bp-md){
            margin: 75px auto 50px;
          }
        }
      }
    }
  }
}