// .gallery-cta
@include block('gallery-cta'){
  background-color: $c_wk-lighter-grey;
  min-height: 400px;

  // .gallery-cta__inner
  @include element('inner'){
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 25px;
    display: block;

    // screens > 768
    @include media($bp-md){
      padding:0;
    }

    // screens > 992
    @include media($bp-lg){
      display: flex;
    }
  }
  // .gallery-cta__image-container
  @include element('image-container'){
    display: none;
    width: 100%;
    @include media($bp-md){
      display:block;
    }
  }

  // .gallery-cta__image-bg
  @include element('image-bg'){
    background-size: cover;
    width: 100%;
    height: 530px;
    background-position: center;
  }

  // .gallery-cta__content
  @include element('content'){
    padding:0;

    // screens > 768
    @include media($bp-md){
      padding: 50px 75px;
    }

    // screens > 992
    @include media($bp-lg){
      padding: 90px 75px 0;
    }
  }

  // .gallery-cta__title
  @include element('title'){
    font-size: 26px;
    color: $c_wk_heading_text-base;
    padding-bottom: 25px;

    // screens > 768
    @include media($bp-md){
      font-size: 32px;
    }
  }

  // .gallery-cta__description
  @include element('description'){
    font-size: 16px;
    line-height: 1.5em;

    //screens > 768
    @include media($bp-md){
      font-size: 20px;
    }
  }

  // .gallery-cta__link-container
  @include element('link-container'){
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px 0 30px;
    max-width: 515px;
    margin: 0 auto;

    // screens > 992
    @include media($bp-lg){
      margin: 0;
      max-width: 315px;
    }
  }
  // .gallery-cta .popup
  @include block('popup'){

    // .gallery-cta .popup__icon
    @include element('icon'){
      width: 30px;
      height: 30px;
    }

    // .gallery-cta .popup__link
    @include element('link'){
      position: relative;

      // .gallery-cta .popup__link .popup__icon--grey
      .popup__icon--grey{
        display: block;
      }

      // .gallery-cta .popup__link .popup__icon--teal
      .popup__icon--teal{
        display:none;
      }

      // .gallery-cta .popup__link:hover
      &:hover{
        // .gallery-cta .popup__link:hover .popup__icon--grey
        .popup__icon--grey{
          display: none;
        }
        // .gallery-cta .popup__link:hover .popup__icon--teal
        .popup__icon--teal{
          display:block;
        }
      }

      // .gallery-cta .popup__link.show
      &.show{
        // // .gallery-cta .popup__link.show:after
        &:after{
          content:'';
          position: absolute;
          top: 30px;
          left: 0;
          border-top: 15px solid transparent;
          border-right: 15px solid transparent;
          border-left: 15px solid transparent;
          border-bottom: 15px solid $c_white;
        }
      }
    }

    // .gallery-cta .popup__content
    @include element('content'){
      display: none;
      position: relative;
      background: $c_white;
      padding: 25px;

      // .gallery-cta .popup__content.show
      &.show{
        display: block;
      }
    }

    // .gallery-cta .popup__title
    @include element('title'){
      font-family: $brandon_bold;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;

      // screens > 768
      @include media($bp-md){
        font-size: 16px;
      }
    }

    // .gallery-cta .popup__text
    @include element('text'){
      padding-bottom:0;
    }
  }
}
