@include block('installation-support-section'){
  background-color: $c_support-background-color;
  
  &.not-residential{
    padding: 0 0 2em;
  }

  @include element('title'){
    font-family: $brandon_regular;
    font-size: 26px;
    padding: 50px 30px;
    @include media($bp-md){
      font-size: 32px;    
      padding: 85px 25px 75px;
    }

    &.not-residential{
      padding: 35px 25px;
      background-color: $c_wk-med-grey;
      color: white;
    }
  }
  @include element('subtitle'){
    font-family: $brandon_bold;
    font-size: 14px;
    text-align: center;
    @include media($bp-lg){
      text-align: left;
    }
  }
  @include element('inner'){
    background: transparent !important;
    @include modifier('shrink'){
      background: transparent;
      &.container{
        max-width: 1200px;
      }
      
      &.installation-support-section__no-video{
        
        max-width:1000px;
        .installation-support-section__fullwidth{
          max-width: 1000px;
          
          .installation-support-section__subtitle{
            display: none;
          }
          .installation-support-section__support-list{
            display: flex;
            flex-wrap: wrap;
            .media{
              flex-wrap: nowrap;
              flex-basis: 100%;
              margin-bottom: 45px;

              &:last-child{
                margin-bottom: 0;
              }

              @include media($bp-md){
                flex-basis: 50%;
                padding: 0 .75em;
                
              }
            }
          }
        }

        
      }

    }
    &.container{
      max-width: 1000px;
    }
  }
  @include element('left'){
  
    @include media($bp-lg){
      width: 50%;
    }
  }

  @include element('video-container'){
    border: 1px solid #DBDBDB;
    border-top: 6px solid maroon;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    max-width: 547px;

    @include media($bp-lg){
      max-width: unset;
    }

  }


  @include element('video-main-description'){
    padding: 25px 30px 15px;
    font-size: 20px;
    text-overflow: initial;
    overflow: auto;
    white-space: normal;
    @include media($bp-md){
      font-size: 24px;
    }
  }
  @include element('video-main-link'){
    padding: 0 30px 25px;
    font-size: 13px;
    font-family: $brandon_black;
    color: $c_wk-maroon;
    text-transform: uppercase;
    width: fit-content;
    position:relative;

    &:after{        
      content: "";
      width: 100%;
      height: 100%;
      background: url(images/icon-right-open_red.svg) no-repeat 50%;
      top: 1px;
      right: 10px;
      position: absolute;
      max-width: 9px;
      max-height: 13px;
    }
  }





  @include element('right'){
    .installation-support-section__subtitle{
      padding-top: 20px;
      @include media($bp-lg){
        padding-top: 0;
      }
    }
    @include media($bp-lg){
      width: 50%;
      padding-left: 100px;
    }
  }
  @include element('support-list'){
    @include block('media'){
      @include element('left'){
        @include media($bp-md){
          width:8% !important;
          margin-right: 10px;
        }
      }
      @include element('head'){
        font-size: 20px;
        font-family: $brandon_regular;
        padding-bottom: 10px;
        @include media($bp-md){
          font-size: 24px;
        }
        a{
          color: $c_wk-dark-teal;
        }
      }

      @include element('body'){
        font-size:16px;
        padding-bottom: 35px;

        &:last-child{
          padding-bottom:0;
        }
        @include media($bp-md){
          font-size: 20px;
        }
      }
      
    }
  }

}