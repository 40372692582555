/// .secondary-menu-toggle
@include block('secondary-menu-toggle') {
  display: flex;

  @include media($bp-md) {
    display: none;
  }
}

@include block('navicon'){
  background-color: $c_white;

  @include element('icon'){
    background-color: $c_wk-maroon;
    
    &::before, 
    &::after{
      background-color: $c_wk-maroon;
    }
  }
}

/// .secondary-menu
@include block('secondary-menu') {
  padding: 0 20px;
  box-shadow: 0 6px 16px -7px hsla(210,1%,45%,.5);
  
  /// screen > 768px
  @include media($bp-md) {
    max-width: 300px;
    padding: 30px 40px;
  }

  /// .secondary-menu__buy-moen
  @include child('buy-moen') {
    display: none;
  }
  
}

/// .featured-inspiration
@include block('featured-inspiration') {
  display: none;
}

/// .featured-inspiration
@include block('tools-nav') {
  display:none;
}