/// .multi-cta
@include block('multi-cta'){
  &.wasteking{
    box-shadow: none;
    margin: 3.5em auto;
    text-align:center;

    /// .text-box
    @include block('text-box'){
      @include element('head'){
        font-family: $brandon_regular;
        font-size: em(26);
        @include media($bp-md){
          font-size: em(32);
        }
      }
    }
  }

  &#about-us{
    margin:0;
    padding: 45px 0;
    background-color:$c_wk-multi-cta_background;

    @include media($bp-md){
      padding: 100px 0;
    }

    @include block('text-box'){

      @include element('inner'){
        background-color:$c_wk-multi-cta_background;
      }
      @include element('head'){
        display: block;
        @include media($bp-md){
          display: none;
        }
      }

      @include element('body'){
        
        display: none;
        @include media($bp-md){
          display: block;
          margin-bottom: 30px;

          p{
            margin-bottom: 18px;
            color: $c_wk_body_text-base;
            font-size: 32px;
          }
        }

      }
    }
  }

  &#product-gallery{
    margin: 0 auto 3.5em;
    .text-box{
      max-width: 800px;
      margin: 0 auto;
      .text-box__head{
        font-size: 22px;
        @include media($bp-md){
          font-size: 28px;
        }
      }
    }
  }
  &#product-detail{
    background-color: $c_support-background-color;
    margin: 0 auto;
    padding: 0 25px 3.5em;

    .text-box{
      max-width:780px;
      margin: 0 auto;
      @include element('inner'){
        background: transparent;
      }
      @include element('head'){
        font-size: 22px;
        @include media($bp-md){
          font-size: 28px;
        }
      }
    }
  }

  @include modifier('horizontal'){
    max-width: 1100px;
    margin: 0 auto;
    padding: .5em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 50px;

    @include media($bp-md){
      flex-direction: row;
    }

    .multi-cta{
      @include element('item'){
        max-width: 500px;
        margin: 0 auto !important;

        @include block('media'){
          @include element('head'){
            font-family: $brandon_bold;
            font-size: 20px;
            color: $c_wk_heading_text-base;

            @include media($bp-md){
              font-size: 28px;

            }
          }

          @include media('body'){
            @include media($bp-md){
              p{
                font-size: 20px;
              }
            }
          }
          
          
        }
        @include block('is-block'){
          .button--secondary{
            padding:14px 6px;
            @include media($bp-xs){
              padding: 14px 30px;
            }
            }
          }
        
      }

    }
  }
}

.multi-cta .text-box__body {
    line-height: 1.2em;
    font-size: 1.25em;
}