@mixin sel-red() {
  background-color: $c_wk-maroon;
  border: none;
  position: relative;
  .form-select {
    appearance: none;
    background-color: $c_wk-maroon;
    border-radius: 0;
    color: $c_white;
    font-family: $brandon_regular;
    padding: 14px .375em 14px 1em;
    border:none;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: em(5px) solid transparent;
    border-right: em(5px) solid transparent;
    border-top: em(5px) solid $c_white;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: .5em;
  }
}

@include block(form-select){
  @include modifier(red) {
    @include sel-red();
  }
  @include modifier(red-border) {
    @include sel-red();
    border: 1px solid $c_white;

    .form-select {
      height: 100%;
    }
  }
}
