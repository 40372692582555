/* WasteKing Color Palette */
$c_wk-dark-maroon: #641215;
$c_wk-maroon: #802428 !default;

$c_wk_lighest-grey: #F8F8F9 !default;
$c_wk-lighter-grey: #F1F2F4 !default;
$c_wk-light-grey: #D1D1D1 !default;

$c_wk-med-grey: #56565C !default;
$c_wk_dark-grey: #2A2A2C !default;

$c_wk-dark-teal: #427082 !default;

$c_wk_heading_text-base: #2A2A2C !default;
$c_wk_body_text-base: #56565C !default;





// WK Header Colors
$c_wk-header_border: $c_wk-light-grey;
$c_wk-header_search-border: $c_wk-light-grey;
$c_wk-header_search-text: $c_wk_body_text-base;
$c_wk-mobile-nav_border: $c_wk-light-grey;
$c_wk-mobile-sub-nav-background: $c_wk-lighter-grey;

// WK Footer
$c_wk_footer_border-color: $c_wk-light-grey;

//// WK Contact US

// banner
$c_wk_banner-hero_link-color: $c_wk-dark-teal;

// card-list support
$c_wk_card-list_background-color: $c_wk_lighest-grey;
$c_wk_card-list_border-color: #DBDBDB;
$c_wk_card-list_title-color: $c_wk-dark-teal;

//Banner
$c_wk-banner-content-left_background: $c_wk-lighter-grey;

//Search Banner
$c_wk-banner-search_input-background-color: $c_wk_lighest-grey;
$c_wk-banner-search_input-border-color: $c_wk-light-grey;

// Multi CTA
$c_wk-multi-cta_background: $c_wk-lighter-grey;

//FAQ
$c_wk_faq_question-background-color: $c_wk_lighest-grey;

// Product gallery
$c_pattens_red: #FFFFFF !default;
$c_facet_dark_red: $c_wk-dark-maroon;
$c_facet_gray: #F4F4F4 !default;
$c_facet_borders: #B8B8B8 !default;
$c_button_filters_bg: $c_moen-bright-blue !default;
$c_button_text_blue: $c_moen-bright-blue !default;
$c_button_filters_bg_hover: #e2e2e2 !default;
$c_facets_bg: $c_moen-bright-blue !default;
$c_select_sort_bg: $c_moen-bright-gray !default;
$_facet_filters_font-color: #78777B !default;

$c_product-video_border-color: #DBDBDB;

// Product Detail Page
$c_features_basic-spec_header-color: #9F9DA4 !default;
$c_support-background-color: #F9F9FA !default;




// bg color mixin
@mixin bgColor($name, $color) {
  .bg--#{$name} {
    background-color: $color;
  }
}
@include bgColor(wk-maroon, $c_wk-maroon);

@include block('section-rule'){
  @include modifier('red'){
    border-color: $c_wk-maroon;
  }
}