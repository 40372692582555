/// .button
@include block(button){
  
  /// .button--search
  @include modifier(search){
    background-image: url("./images/magnifying-glass-red_thin.svg");
  }

  /// .button--cta
  @include modifier(cta){
    background-color: $c_wk-maroon;
    font-size: em(13);
    &:hover{
      background-color: $c_wk-dark-maroon;
    }
  }

  ///.button--arrow-right
  @include modifier(arrow-right){
    background-image: url('./images/icon-right-open_red.svg');
    min-width: 19px;
    border: none;
    height: 19px;
    width: 19px;
    position: relative;
    top: -2px;
    background-repeat: no-repeat;
  }

  /// .button--teal-hover
  @include modifier(teal-hover) {
    padding: .5em;
    border: none;
    color: #427082;
    
    &:hover,
    &:active,
    &:focus {
      background-color: $c_wk-dark-teal;
      color: $c_white;
    }
  }

  /// .button-secondary
  @include modifier(secondary) {
    box-shadow: none;
    background-color: $c_wk-maroon;
    border-color: transparent;
    border-radius: 0;
    padding: 14px 30px;
    font-family: $brandon_black;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      background-color: $c_wk-dark-maroon;
      text-decoration: none !important;
    }
  }

  @include modifier(default-border) {
    color: $c_white;
    border-color: $c_white;
  }
}