// Utility Classes
//
// Styleguide 9.0

@include state(block) {
  display: block;
}

.has-centered {
  text-align: center;
}

// RESPONSIVE iFRAME EMBED TECHNIQUE:
@include block(iframe) {
  @include element(wrapper) {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@include state(hidden) {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.no-pointer {
  cursor: default;
}

// AngularJS-specific selectors:
[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
  &.no-pointer {
    cursor: default;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

// ng-messages styles:
@include block('form') {
  @include element(error-message) {
    .ng-active {
      opacity: 1;
    }
    .ng-inactive {
      opacity: 0;
    }
  }
}



.xxxs--block,
.xxxs--inline,
.xxxs--inline-block,
.xxs--block,
.xxs--inline,
.xxs--inline-block,
.xs--block,
.xs--inline,
.xs--inline-block,
.sm--block,
.sm--inline,
.sm--inline-block,
.md--block,
.md--inline,
.md--inline-block,
.lg--block,
.lg--inline,
.lg--inline-block,
.xl--block,
.xl--inline,
.xl--inline-block {
  display: none !important;
}


$bp-xxxs-max: rem(319);
$bp-xxs-max: rem(447);
$bp-xs-max: rem(543);
$bp-sm-max: rem(767);
$bp-md-max: rem(991);
$bp-lg-max: rem(1199);



// xxxs
@include block('xxxs') {

  @include media(min-width $bp-xxxs max-width $bp-xxxs-max) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }
  }
}


// xxs
@include block('xxs') {

  @include media(min-width $bp-xxs max-width $bp-xxs-max) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }
  }
}


// xs
@include block('xs') {

  @include media(min-width $bp-xs max-width $bp-xs-max) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }
  }
}


// sm
@include block('sm') {

  @include media(min-width $bp-sm max-width $bp-sm-max) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }
  }
}


// md
@include block('md') {

  @include media(min-width $bp-md max-width $bp-md-max) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }
  }
}


// lg
@include block('lg') {

  @include media(min-width $bp-lg max-width $bp-lg-max) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }
  }
}

// xl
@include block('xl') {

  @include media($bp-xl) {
    @include modifier('block') {
      display: block !important;
    }

    @include modifier('inline') {
      display: inline !important;
    }

    @include modifier('inline-block') {
      display: inline-block !important;
    }

    @include modifier('hidden') {
      display: none !important;
    }

  }
}




/*
Padding Styling

Padding utility classes that can be added to element. Padding numbers are 5 through 150 in increments of 5.
*<em><strong>class sg-placeholder is for Styleguide use only</strong></em>

markup:
<div class="sg-placeholder padding--25">
  Padding 25px on all sides
</div>
<br />
<div class="sg-placeholder padding--top-25">
  Padding top 25px
</div>
<br />
<div class="sg-placeholder padding--bottom-25">
  Padding bottom 25px
</div>
<br />
<div class="sg-placeholder padding--top-bottom-25">
  Padding 25px on top and bottom
</div>
<br />
<div class="sg-placeholder padding--left-right-25">
  Padding 25px on left and right
</div>

Styleguide 9.1
*/

@mixin paddingStyles() {
  @for $i from 1 through ceil(150 / 5) {
    $value: $i * 5;
      
    .padding--#{$value} { 
      padding: #{$value}px; 
    }
    
    .padding--top-bottom-#{$value} {
      padding-top: #{$value}px;
      padding-bottom: #{$value}px;
    }
    
    .padding--left-right-#{$value} {
      padding-left: #{$value}px;
      padding-right: #{$value}px;
    }
      
    .padding--bottom-#{$value} {
      padding-bottom: #{$value}px;
    }
      
    .padding--top-#{$value} {
      padding-top: #{$value}px;
    }
  }
}

@mixin marginStyles() {
  @for $i from 1 through ceil(150 / 5) {
    $value: $i * 5;
      
    .margin--#{$value} { 
      margin: #{$value}px; 
    }
    
    .margin--top-bottom-#{$value} {
      margin-top: #{$value}px;
      margin-bottom: #{$value}px;
    }
    
    .margin--left-right-#{$value} {
      margin-left: #{$value}px;
      margin-right: #{$value}px;
    }
      
    .margin--bottom-#{$value} {
      margin-bottom: #{$value}px;
    }
      
    .margin--top-#{$value} {
      margin-top: #{$value}px;
    }
  }
}

@include paddingStyles;
@include marginStyles;




.bleed {
  position: 
  relative;

  &::before,
  &::after {
    background-color: inherit;
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    width: calc(50vw - 50%);
    height: 100%;
    pointer-events: none;
  }

  &::before {
    left: 0;
    transform: translateX(-100%);
  }

  &::after {
    right: 0;
    transform: translateX(100%);
  }
}

.overflow-hidden {
  overflow: hidden;
}
