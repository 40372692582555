@include block('downloads'){
  @include media($bp-md){
    padding: 0 20px;
    max-width: 1170px;
  }

  .gtm_faq_text{
    font-size: 13px;
    text-align:center;
    @include media($bp-md){
      font-size: 15px;
    }
  }
  .faq-question-list{
    margin-bottom:0;
  }
  .faq__answer{
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include media($bp-md){
      // flex-direction: row;    
      padding: 0;
      justify-content: flex-start;
    }
    
    .downloads{
      @include element('item'){
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: normal;
        justify-content: flex-start;
        width: 280px;


        &:last-child{
          margin-bottom: 0;
        }
        @include media($bp-md){
          margin: 25px 20px 60px;

          &:last-child{
            margin-bottom: 60px;
          }
        
        }

        @include media($bp-lg){
          margin: 25px 45px 60px;
          // flex-basis: 25%;
        }

        li{
          list-style: none;
          padding-bottom: 18px;
        }

        
      }
      @include element('title'){
        font-size: 20px;
        margin-bottom: 10px;

        @include media($bp-md){
          font-size: 24px;
        }
      }
      @include element('answer'){
        img{
          
        }
        a {
          font-family: $brandon_medium;
          font-size: 13px;
          text-transform: uppercase;
          color: $c_wk-dark-teal;   
          

          img{
            margin-right: 10px;
            height: auto;
            width: 100%;
            max-height: 22px;
            max-width: 22px;
          }     
        }

      }  
      
      
    }
  }

    #digital-catalog-subcat{
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      @include media($bp-md){
        margin: 0;
      }
      a{
        text-decoration:none;
        font-size: 13px;
        text-align:center;
        font-family: $brandon_bold;
        font-size: 15px;
        color: #2a2a2c;
        text-transform: uppercase;

        @include media($bp-md){
          font-size: 15px;
        }
        &:hover{
          p{
            background: #eaeaea;
          }
        }
      }
      img{
        max-width: 250px;
      }
      p {
        margin: 0 auto;
        padding-top: 15px;
        padding: 15px 0px;
        background: #f8f8f9;
        width: 100%;
        max-width: 250px;
        font-weight: 800;
        font-family: $brandon_bold;
      }
    }
  


  .section-heading--2{
    font-size: 26px;

    @include media($bp-md){
      font-size: 42px;
    }
  }


  .faq-list__category--level-1__item.no-caret:after{
    border-top:none;
  }
  
}

.faq-list .small{
  &.no-size{
    &:before,
    &:after{
      content:'';
    }
  }
}
