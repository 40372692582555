@include block('content-container'){
  &#about-us{
    padding: 50px 25px;

    @include media($bp-md){
      padding: 80px 25px;
    }

    .content-container__title{
      font-size: em(20);
      text-transform: uppercase;
      font-family: $brandon_bold;
      color: $c_wk_heading_text-base;
      margin-bottom: 13px;

      @include media($bp-md){
        font-size: em(28);
        margin-bottom: 32px;
      }
    }

    .content-container__content{
      font-size: em(18);
      color: $c_wk_body_text-base;
      text-align: center;
      line-height: em(26);

      @include media($bp-md){
        font-size: em(20);
        max-width: 900px;
      }
    }
  }
}