@include block('hero-section'){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include media($bp-md){
    flex-direction: row;
    padding-bottom: 0;
  }
  @include element('image'){

    .pdp-multimedia{
      display: none;
    }

    @include media($bp-md){
      margin: 0 40px;
      .pdp-multimedia{
        display: none;
        padding-top: 20px;
        width: 100%;
        max-width: 1350px;

        @include element('button'){
          button{
            background-size: 50%;
            top: 100px;
          }
        }

      }

    }
    @include media($bp-lg){
      display: inline-block;
      width: 50%;
    }

    img{
      max-width: 400px;
      max-height:400px;

    }

  }
  @include element('description'){
    margin: 0 auto 0 0;
    border-bottom: none;
    @include media($bp-sm){
      margin: 0 auto;
    }
    @include media($bp-lg){
      display: inline-block;
      width: 50%;
      transform: translate(0, -46%);


      .wk-pdp-commercial-part & {
        transform: none;
      }
    }
  }

  @include element('model'){
    font-size: 13px;
    text-transform:uppercase;
    font-family: $brandon_regular;
    margin-bottom: -13px;
    @include media($bp-md){
      margin-bottom: -10px;
    }
  }
  @include element('text'){
    font-family: $brandon_regular;
    font-size: 32px;
    @include media($bp-md){
      font-size: 42px;
    }
  }
  @include element('buy'){
    padding-bottom: 0;
  }
  @include element('buy-price'){
    font-family: $brandon_regular;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: baseline;
    span{
      font-family: $brandon_bold;
      font-size: 24px;
      margin-right: 4px;
    }

  }
}
