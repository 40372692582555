@include block('features-section'){
  @include element('title'){
    text-align: center;
    margin: 0 auto;
    background: $c_wk-med-grey;
    color: $c_white;
    padding: 35px 25px;
    font-size: 26px;

    @include media($bp-md){
      font-size: 32px;
    }
  }

  @include block('gallery-cta'){
    background-color:$c_white;
    min-height: unset;

    @include element('inner'){
      max-width: unset;
      margin: 0 auto;
      padding: 0px 20px;
      display: block;
    }

    @include element('content'){
      padding: 0;
      @include media($bp-md){
        padding: 30px 0 0;
      }
      @include media($bp-lg){
        padding: 60px 0 0;
      }
    }
    @include element('link-container'){
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      max-width: unset;
      flex-wrap: wrap;
      padding: 24px 0 0;

      @include media($bp-md){
        margin: 0;
        padding: 0;
      }

      @include block('popup'){

        @include element('link'){
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding:0 0 10px;
          flex-basis: 300px;
          position: relative;

          &.show{
            &:after{
              z-index:9999;
              border: none;
              content: "◥";
              transform: rotate(-45deg);
              top: 55px;
              color: white;
              left: 6px;
              text-shadow: 2px -2px 2px #EAEAEA;
            }
          }
          &:last-child{
            padding-bottom: 0;
          }


          @include media($bp-xs){
            flex-basis: 200px;
            &:last-child{
              padding-bottom: 10px;
            }
          }
          @include media($bp-lg){
            padding: 0 0 20px;
            font-size: 14px;
          }

        }
        @include element('description'){
          padding: 0;
          font-family: $brandon_bold;
          font-size: 14px;
          color: $c_wk_heading_text-base;
          text-transform: uppercase;
          margin-left: 15px;
        }

        @include element('content'){
          position: absolute;

          &.show{
            z-index:2;
            top: 63px;
            left:-5px;
            box-shadow: 0px 0px 6px 2px #EAEAEA;
            @include media($bp-xs){
              left: -10px;
              width: 300px;
            }
            @include media($bp-sm){
              left: -15px;
              width: 330px;
            }
            @include media($bp-lg){
              left: -20px;
            }
          }
        }

      }
    }
  }

  @include element('heading'){
    font-family: $brandon_regular;
    font-size: 26px;

    @include media($bp-md){
      font-size: 32px;
    }
  }

  @include element('features-list'){
    font-size: 16px;

    @include media($bp-md){
      font-size: 20px;
    }
  }

  .col-md-5{
    background-color: $c_wk-lighter-grey !important;
  }
  @include element('label'){
    font-family: $brandon_regular;
    font-size: 26px;
    text-transform: capitalize;

    @include media($bp-md){
      font-size: 32px;
    }
  }
  @include block('basic-specs'){
    dl {
      dt{
        font-family: $brandon_bold;
        font-size: 11px;
        color: $c_features_basic-spec_header-color;
        padding-bottom: 10px;
        text-transform: uppercase;
      }
      dd{
        font-family: $brandon_regular;
        font-size: 16px;
        color: $c_wk_body_text-base;
        padding-bottom: 45px;
        @include media($bp-md){
          font-size: 20px;
        }
      }
    }

    .link__icon--download{
      color: $c_wk-dark-teal;
      &:before{
        background-image:url('./images/icon-download.svg');
        background-size: cover;
      }
    }

  }

}
