// using the knockout banner variant


$specificMobileSize: 370px;

/// .wasteking
@include block('wasteking') {
  &.overflow-hidden {
    margin-top: 0;
  }
  .bleed {
    &:before,
    &:after{
      height: 95.5%;
    }
  }

  @include block('banner'){
    &.banner--homepage{
      .banner__image-bg {
        min-height: 425px;
        width: 100%;
        max-width: unset;

        @include media($bp-md) {
          height: 100%;
        }
      }
      .banner__inner{
        position: absolute;
        top:0;
        left: 0;
        min-height: 425px;
        background-color: transparent;

      }

      .banner__inner-left{
        .text-box{
          background:transparent;
          padding: 50px 40px;

          @include media($bp-md) {
            padding: 0px 30px 50px 70px;
          }

          @include media($bp-lg) {
            padding: 0px 30px 50px 100px;
          }

          @include media($bp-xl) {
            padding: 0px 30px 50px 130px;
          }

          .text-box__inner {
              padding: 1em;
              background-color: hsla(200,1%,55%,.5);
          }

        }
      }
    }
  }
}


/// did it this way to override the !important tags from moen
.wasteking .homepage__banner--video .banner .banner__inner .text-box .text-box__subtitle{
  color: $c_white !important;
  @media (min-width: $specificMobileSize) {
    color: $c_white !important;
  }
}

.wasteking .homepage__banner--video .banner .banner__inner .text-box .text-box__body{
  color: $c_white !important;
  @media (min-width: $specificMobileSize) {
    color: $c_white !important;
  }

}
