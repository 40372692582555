.plmr-u-bg--brand-primary {
    background-color: #802428
}

.plmr-u-bg--background-primary {
    background-color: #ffffff
}

.plmr-c-general-content, 
.plmr-c-general-content strong, 
.plmr-c-content-area__title,
.plmr-c-form-label,
.plmr-c-form-text--helper
{
    font-family: inherit;
}