//using the innovation buckets variant

/// .wasteking
@include block('wasteking'){

  .text-center{
    padding: 0 20px;
    max-width: 900px;
    margin: 0 auto 45px;
    
    /// .card-list
    @include block('card-list'){
      /// .card-list__heading
      @include element('heading'){
        text-transform: uppercase;
        color: $c_black;
        font-family: $brandon_bold;
        font-size: em(20);
        color: $c_wk_heading_text-base;

        @include media($bp-md){
          font-size: em(28);
        }
      }
    }

    p{
      color: $c_wk_body_text-base;
      font-size: em(16);
      @include media($bp-md){
        font-size: em(20);
      }
    }
  }

  

  ///  .card-list-animated
  @include block('card-list-animated'){
    max-width: 1040px;
    width: 100%;
    
    @include media($bp-md){
      width: 100%;
    }

    /// .card-animated
    @include block('card-animated'){
      min-height: 136px;
      margin: 15px auto;
      background-color: $c_wk-lighter-grey;
      max-width: 338px;
      max-height: 450px;

      @include media($bp-md){
        min-height: 440px;
        margin: 10px 9px;
        &:first-child{
          margin-left:0;
        }
        &:last-child{
          margin-right:0;
        }
      }

      &:before{
        background: none;
      }

      @include element('inner'){
        background-color: $c_wk-lighter-grey;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        height: 136px;

        @include media($bp-md){
          height: 440px;
          align-items: center; 
        }
      }

      @include element('image'){
        max-height: 136px;
        height: 100%;
        float: right;
        margin-right: 20px;

        @include media($bp-md){
          max-height: 300px;
          float: none;
          margin-right: 0;
        }
      }
      @include element('captions'){
        bottom: 0;
        top: calc(50% - 50px);
        text-align: left;

        @include media($bp-md){
          text-align: center;
          top:40px;
        }
      }
      @include element('caption'){
        

        @include modifier('1'){
          font-family: $brandon_regular;
          padding-bottom: em(15);
          color: $c_wk-dark-grey;
          font-size: em(32);
          padding-bottom: 18px;

          @include media($bp-md){
            font-size: em(40);
            padding-bottom: 12px;
          }
        }
        @include modifier('2'){
          font-family: $brandon_regular;
          color: $c_wk-dark-grey;
          font-size: em(18);
          padding-bottom: em(12);

          @include media($bp-md){
            text-align: center;
            font-size: em(20);
            padding-bottom: 0;
          }
        }

        @include modifier('3'){
          font-family: $brandon_black;
          color: $c_wk-maroon;
          text-transform: uppercase;
          font-weight: 800;
          font-size: em(13);

          @include media($bp-md){
            text-align: center;
          }
        }
      }
    }
  }
  
}