#wk-search{
  .search-results-page-header{
    margin: 3em 0;
    h1{
      font-family: $brandon_regular;
    }
  }

  .search-results-navigation__item .button{
    background-color: $c_wk-maroon;
    font-family: $brandon_regular;
  }
}

.tile-row-button {
    width: 100%;
}

@include block('search-results'){
  &.wasteking{
    .search-results-section-header{
      border-bottom: 3px solid $c_wk-maroon;
      @include child('link'){
        color: $c_wk-maroon;
      }
    }
  }
}
