@include block('product-gallery'){

  @include block('facets'){
    @include element('main'){
      padding: 1.25em 1.5em;
      background-color: $c_wk-maroon;
      justify-content: center;
    }
    @include element('button-filters'){
      background-color: $c_wk-maroon;
      padding:16px 1em;
    }

    @include element('filters'){

      &.is-active{
        position: relative;
        @include media($bp-sm){
          right: calc(50% - 218px);
        }
        @include media($bp-lg){
          right: 0;
        }
      }
    }

    @include element('inner'){
      background-color:$c_wk-lighter-grey;
    }


    @include element('button'){
      background-color: $c_wk-light-grey;
      color: $c_white;
      border: none;
      font-family: $brandon_black;
      text-transform: uppercase;
      font-size: 13px;
      word-spacing: 2px;
      
      @include media($bp-lg){
        background-color: transparent;
        border-bottom: 5px solid $c_wk-light-grey;
        color: $_facet_filters_font-color;
        font-family: $brandon_regular;
        text-transform: capitalize;
        font-size: 18px;
        word-spacing: 2px;
        min-width: 200px;
        text-align: center;

        &:hover{
          color:inherit;
        }
      }

      &.is-active{
        background-color: $c_wk-maroon;
        border: none;

        @include media($bp-lg){
          background-color: transparent;
          border-bottom: 5px solid $c_wk-dark-teal;
          color:$c_wk-dark-teal;

          &:hover{
            background-color: #e2e2e2;
          }
        }
      }

      &:after{
        border-top-color: $c_white;
      }
    }
    @include element('main-buttons'){
      @include media($bp-sm){
        flex-basis:auto;
      }
    }

    @include element('clear-all'){
      background-color:transparent;
      text-transform: capitalize;
      font-family: $brandon_regular;
      border: none;
      border-bottom: none !important;
      color: white !important;
    }

    @include element('button-apply-filter'){
      background-color: $c_wk-dark-teal;

      @include media($bp-sm){
        padding: 16px 1em;
      }
      
      @include media($bp-lg){
        background-color: $c_wk-maroon;
        font-size: 13px;
        text-transform: uppercase;
        font-family: $brandon_black;
        padding: 13px 25px;
        margin-bottom: 13px;
      }
    }

    @include element('ck'){
      font-family: $brandon_regular;
      &.form-checkbox--white{

        @include media($bp-lg){
          font-size: 18px;
        }
        [type="checkbox"] {
          &:not(:checked),
          &:checked {
            & + label:before {
              background-color: $c_white;
              height: 22px;
              top: -2px;
              left: -4px;
              width: 22px;
            }
          }
          &:checked {
            & + label:after {
              content: '';
              height: 17px;
              top: 0px;
              left: -2px;
              width: 17px;
              background: url(./images/icon-checkmark_teal.svg) no-repeat;
            }
          }
        }
      }
    }


  }

  @include block('results'){
    max-width: 1200px;

    @include element('header'){
      margin: 0 25px 2em;
      @include media($bp-md){
        margin: 0 0 2em;
      }
    }
  }
}