
/// .global-footer-container
@include block('global-footer-container') {
  flex-direction: column;
  align-content: center;
  align-items: center;

  @include media($bp-sm){
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
  }
  @include child('col'){
    @include modifier('last'){
      display: none;
    }
  }
}

/// .global-footer
@include block('global-footer') {
  border-top: 1px solid $c_wk_footer_border-color;
  @include media($bp-md){
    margin-bottom: 0;
  }
  @include element('logo'){
    margin-bottom:10px;
    @include media($bp-md){
      margin-bottom:0;
    }
    @include element('image'){
      height: 65px;
      width: 111px;
      @include media($bp-md){
        width: 180px;
        height:40px;
      }
      @include modifier('horizontal'){
        display: none;
        @include media($bp-md){
          display: block;
        }
      }
      @include modifier('stacked'){
        display: block;
        position: relative;
        left: 4px;
        margin: 0 auto;
        @include media($bp-md){
          display: none;
        }
      }
    }
  }
  @include element('copyright'){
    text-align: center;
    @include media($bp-md){
      margin-left: -7px;
    }
  }
}

/// .footer-nav
@include block('footer-nav') {
  flex-direction: column;
  margin-bottom: 0;
  
  @include media($bp-sm){
    flex-direction: row;

    justify-content: flex-end !important;
  }

  /// .footer-nav__list
  @include child('list'){
    text-align:center;
    margin: 0 0 rem(30) 0;
    padding-bottom: 10px;

    @include media($bp-sm){
      text-align: right;
      margin: 0 0 0 rem(60);
    }

    &:first-child {
      margin-right: 0;
    }
     &:last-child{
      margin-bottom:0;
     }
    
  }

  @include element('item'){
    color: $c_wk_body_text-base;
    font-size: 16px;
    font-family: $brandon_regular;
    padding-bottom: 7px;

    &:first-child{
      padding-bottom: 12px;
    }

    @include media($bp-md){
      padding-bottom: 0;
    }
    a {
      color: $c_wk_body_text-base;

      &.footer-header {
        color: $c_wk_heading_text-base;
        font-family: $brandon_black;
        font-size: 13px;
        text-transform: uppercase;
      }
    }

    .footer-header {
      color: $c_wk_heading_text-base;
      font-family: $brandon_black;
      font-size: 13px;
      text-transform: uppercase;
    }

    
  }


}

