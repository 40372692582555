/// .videos
@include block('videos'){

  /// .videos__inner
  @include element('inner'){
    max-width: 1300px;
    margin: 0 auto;
  }

  /// .videos__list
  @include element('list'){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
  }

  /// .video
  @include block('video'){
    margin: 20px 0 35px;
    text-align: center;
    text-decoration: none;
  
    /// screens > 768px
    @include media($bp-md){
      margin-bottom: 55px;
    }
    /// .videos .video:hover
    &:hover{
      text-decoration: none;
    }

    /// .videos .video:first-child
    &:first-child{
      margin-top: 0px;
    }

    /// .videos .video:last-child
    &:last-child{
      margin-bottom: 60px;

      /// screens > 768px
      @include media($bp-md){
        margin-bottom: 140px;
      }
    }

    /// .video__image-container
    @include element('image-container'){
      position: relative;

      /// .video__image-container img
      img{        
        width: 100%;
        height: auto;
        max-width: 775px;
        max-height: 435px;
        
      }

    }

    /// .video__play-icon
    @include element('play-icon'){
      position: absolute;
      background-image:url('./images/icon-play_outline_white.svg');
      height: 40px;
      width: 40px;
      top: calc(50% - 20px);
      left: calc(50% - 20px);

      /// screens > 768px
      @include media($bp-md){
        height: 95px;
        width: 95px;
        top: calc(50% - 45px);
        left: calc(50% - 45px);
      }
    }

    /// .video__title
    @include element('title'){
      padding-top:25px;
      font-size: 18px;
      font-family: $brandon_regular;
      color: $c_wk_heading_text-base;

      /// .screens > 768px
      @include media($bp-md){
        padding-top: 35px;
        font-size: 24px;
      }
    }
  }
}