@include block('card-list'){
  @include modifier('text-left-2-tall-right'){
    .card-list{
      @include element('content'){
        border-top: 4px solid $c_wk-maroon;
      }
      
    }
    .card{
      @include element('back-content'){
        background-color: $c_wk-maroon;
      }
    }
  }
}