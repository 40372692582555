@font-face {
  font-family: 'Brandon-light';
  src: url('./fonts/Brandon-light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Brandon-regular';
  src: url('./fonts/Brandon-regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Brandon-medium';
  src: url('./fonts/Brandon-medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Brandon-bold';
  src: url('./fonts/Brandon-bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Brandon-black';
  src: url('./fonts/Brandon-black.ttf') format('truetype');
  font-weight: 900;
}

$brandon_light: "Brandon-light", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !default;
$brandon_regular: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !default;
$brandon_medium: "Brandon-medium", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !default;
$brandon_bold: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !default;
$brandon_black: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !default;
