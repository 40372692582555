@include block('product-tile'){
  width: 50%;
  max-width: 159px;
  border: 1px solid transparent;

  @include media($bp-md){
    width: 25%;
    max-width: 250px;
    margin: 1px;
  }

  @include media($bp-xl){
    max-width: 298px;
  }

  @include media($bp-xxl){
    max-width: 298px;
  }

  @include element('head'){
      .product-tile__like{
          display: none;
      }
  }
  @include child('name'){
    max-width: 325px;
    font-size: 20px;
    color: $c_wk-dark-teal;
    line-height: 1em;

    @include media($bp-md){
      font-size: 24px;
    }
  }

  @include element('image'){
      max-height: 165px;
      width: auto;
      max-width:unset;
  }

  @include element('msrp'){
    display: inline-flex;
  }

  @include element('sku'){
    text-transform: uppercase;
    font-size: 13px;
    font-family: $brandon_medium;
    color: $c_wk_body_text-base;
  }
}

@include block('tile'){

  &:hover,
  &:focus{
    background-color: white;
    box-shadow: 0px 0px 6px 0px #EDEDED;
    border: 1px solid #DBDBDB;
  }
}

@include block('tile-row'){
  @include media($bp-lg){
    justify-content: center;
  }
}
