#wasteking{
  @include block('page-title'){
    @include child('h1'){
      font-family: $brandon_medium;
    }
    @include child('head'){
      padding: 1em;
      justify-content: center;
    }
    @include child('headings'){
      align-items: center;
    }

  }
}