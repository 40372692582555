/// .search-banner
@include block('search-banner'){
  
  /// .search-banner #FAQ
  &#FAQ,
  &#downloads{

    // adding this so i can use BEM mixins
    .search-banner{
      
      /// .search-banner__content
      @include element('content'){
        max-width: 900px;
        margin: 50px 5px;

        /// screens > 768px
        @include media($bp-md){
          margin:90px auto 100px;
        }
      }

      /// .search-banner__heading
      @include element('heading'){
        font-size: 26px;
        font-family: $brandon_bold;
        text-transform: uppercase;
        letter-spacing: 0;
        color: $c_wk_heading_text-base;

        /// screens > 768px
        @include media($bp-md){
          font-size: 48px;
        }
      }

      /// .search-banner__search-form
      @include element('search-form'){
        max-width: 620px;
        margin: 0 auto;
        position: relative;
        
        /// .search-banner__search-form .form-input
        .form-input{
          height: 60px;
          font-size: 18px!important;
          text-indent: 15px;
          background-color: $c_wk-banner-search_input-background-color;
          border: 1px solid $c_wk-banner-search_input-border-color;
        }

      }

      /// .search-banner__button
      @include element('button'){
        position: absolute;
        right: 10px;
        top: calc(50% - 12px);
        border: none;
      }
    } 
  }
}