// .product-videos
@include block('product-videos'){
  padding: 50px 25px;

  //screens > 768
  @include media($bp-md){
    padding: 100px 25px;
  }

  // .product-videos__inner
  @include element('inner'){
    max-width: 800px;
    margin: 0 auto;
  }

  // .product-videos__title
  @include element('title'){
    padding-bottom: 40px;
    font-size: 26px;
    text-align: center;

    //screens > 768
    @include media($bp-md){
      padding-bottom: 60px;
      font-size: 32px;
    }
  }

  // .product-videos__container
  @include element('container'){
    display: flex;
    flex-direction: column;

    // screens > 768
    @include media($bp-md){
      flex-direction: row;
    }

    // .product-videos .product-video
    @include block('product-video'){
      border: 1px solid $c_product-video_border-color;
      border-top: 7px solid $c_wk-maroon;
      max-width: 380px;
      margin: 0 auto 30px;

      // screens > 768
      @include media($bp-md){
        margin: 0 10px;
      }

      // .product-videos .product-video__description
      @include element('description'){
        padding: 40px 30px 16px;
        font-size: 20px;

        // screens > 768
        @include media($bp-md){
          font-size: 24px;
        }
      }

      // .product-videos .product-video__link
      @include element('link'){
        padding: 0 30px 0px;
        font-size: 13px;
        font-family: $brandon_black;
        text-transform: uppercase;
        color: $c_wk-maroon;
        position: relative;

        // .product-videos .product-video__link:after
        &:after{        
          content: "";
          width: 100%;
          height: 100%;
          background: url(images/icon-right-open_red.svg) no-repeat 50%;
          top: 3px;
          right: 10px;
          position: absolute;
          max-width: 9px;
          max-height: 13px;
        }

        // .product-videos .product-video__link:hover
        &:hover{
          text-decoration: none;
        }
      }

      // .product-videos .product-video__image-bg
      @include element('image-bg'){
        background-size: cover;
        height: 185px;
        margin-top: 30px;
      }
    }
  }
}