// .card-list--text-only
@include block('card-list--text-only'){

  // .card-list--text-only #contact-us
  &#contact-us{
    background-color: $c_white;
    padding-bottom: 60px;
    
    .card-list{
      // .card-list__title
      @include element('title'){
        font-size: 26px;

        //screen > 768
        @include media($bp-md){
          font-size: 42px;
        }
      }
      // .card-list__list
      @include element('list'){
        text-align: left;
        max-width: 1170px;
        justify-content: flex-start;
      
        .card{
          align-items: flex-start;
          padding: .5em 24px;

          //card--text-only
          @include modifier('text-only'){
            text-align: left;
            min-width: 317px;
            max-width: 360px;
          }
          // .card__title
          @include element('title'){
            font-size: 15px;
            font-family: $brandon_bold;
            text-transform: uppercase;
            letter-spacing: 0;
            margin-bottom: 10px;
            color: $c_wk_heading_text-base;
          }
          // .card__subtitle
          @include element('subtitle'){
            // .card__subtitle p
            p{
              font-size: 16px;
              padding-bottom: 5px;
              @include media($bp-md){
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}