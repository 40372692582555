/// .mobile-nav
@include block('mobile-nav') {
  
  margin-bottom:0;
  border-top:1px solid $c_wk-mobile-nav_border;

  /// .mobile-main-nav__link
  @include child('link') {
    border-top: none !important;
    border-bottom: 1px solid $c_wk-mobile-nav_border;
    text-transform: uppercase;
    font-size: em(15);
    font-family : $brandon_black;
    font-weight: bold;

    &::after {
      height: 13px;
      width: 13px;
      background-image: url('./images/icon-right-open_red.svg');
      background-repeat: no-repeat;
    }

    &:hover{
      background: $c_white;
      color: inherit;
    }

    @include modifier('active'){
      background: $c_white;
      color: inherit;
      &::after{
        transform: rotate(180deg);
      }
    }
    /// .mobile-main-nav__link--additional
    @include modifier('additional') {
      display:none;
    }
    
  }

}

/// .mobile-sub-nav
@include block('mobile-sub-nav') {
  @include element('list'){
    font-family: $brandon_bold;
    text-transform: uppercase;
    font-size: em(13);
    margin-bottom: 0;
  }
  
  @include element('link'){
    padding: 30px 50px 30px 20px;
    border-bottom: 1px solid $c_wk-mobile-nav_border;
    background-color: $c_wk-mobile-sub-nav-background;
    
    &::after {
      content:'';
      height: 13px;
      width: 13px;
      background-image: url('./images/icon-right-open_red.svg');
      background-repeat: no-repeat;
      position: absolute;
      right: 27px;
    }

    @include media($bp-md){
      padding: 12px 20px;
    }
    
  }

  @include element('image'){
    display: none;
  }

  @include element('label'){
    position: relative;
    left: 55px;
  }
  
  @include element('item'){
    border-bottom: none;
  }

  @include element('view-all'){
    display: none;
  }
}