/// .site-header-search
@include block('site-header-search') {
  order: 1;
  width:100%;
  display: block;
  border-top: 1px solid $c_wk-header_search-border;

  &.hide{
    display: none;
  }
  
  /// screen > 448px
  @include media($bp-xs) {
    margin: 0 15px 0 0;
  }

  /// screen > 544px 
  @include media($bp-sm){
    width: 55%;
    max-width: 325px;
    margin: 19px 15px 18px 0;    
    order: 0;
    border-top: none;
  }
  .container{
    margin: 0;
    min-width: 100%;

    .search-results-page-header{
      margin-bottom: 0;
      .input-add-on{
        width: 100%!important;
        flex: 1 0 40%!important;

        .input-add-on__field{
          border-right: none;
        }
        .input-add-on__item{
          border-left: none;
        }
      }
    }
  }
  @include element('icon'){
    border: none;
    height: 30px;
    width: 30px;
    order: 0;
    margin-right: 10px;
    display: block;    

    &.hide{
      display: none;
    }

    @include media($bp-sm){
      margin: 18px 25px 17px 0;
    }
  }

}


/// .header-search-container
@include block('header-search-container') {
  display: none;
  

  /// .site-header-search--mobile .header-search-container
  .site-header-search--mobile & {  
    background: white;
    box-shadow: none;
    display: block;
    padding: 0 10px;
    position: initial;
    width: 100%;
    
  }
  
}

/// .search-box
@include block('search-box') {
  border: none;
  height: 40px;

  /// screen > 544px
  @include media($bp-sm){
    border: 1px solid $c_wk-header_search-border;
    height: 35px;
  }

  /// screen > 768px
  @include media($bp-md){
    border: 1px solid $c_wk-header_search-border;
  }

  @include child('input') {
    align-self: stretch;
    background: transparent;
    color: $c_wk-header_search-text;
    flex: 1 1 auto;
    font-family: $din-regular;
    font-size: 14px;
    padding: 0 15px;
    font-size: 14px !important;
    
    &#search_terms{
      font-size: 14px !important;
    }

    /// screen > 448px
    @include media($bp-xs) {
      max-width: none;
    }

    /// screen > 992px
    @include media($bp-lg) {
      max-width: none;
      padding: 0 13px;
      font-size: 14px !important;
    }
    
  }
  @include element('button'){
    background-image: url('images/magnifying-glass-grey_thin.svg');
    @include media($bp-sm){
      background-image: url('images/magnifying-glass-red_thin.svg');
    }
  }
}
