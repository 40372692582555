@charset "UTF-8";
/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400i");
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

audio:not([controls]) {
  display: none; }

b,
strong {
  font-weight: bolder; }

button {
  -webkit-appearance: button;
  overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

button,
select {
  text-transform: none; }

details {
  display: block; }

hr {
  overflow: visible; }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%; }

input {
  -webkit-border-radius: 0; }
  input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; }
  input[type="number"] {
    width: auto; }
  input[type="search"] {
    -webkit-appearance: textfield; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

main {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

summary {
  display: block; }

svg:not(:root) {
  overflow: hidden; }

template {
  display: none; }

textarea {
  overflow: auto; }

[hidden] {
  display: none; }

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit; }

* {
  font-size: inherit;
  line-height: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0; }

*,
::before,
::after {
  border-style: solid;
  border-width: 0; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

svg {
  fill: currentColor; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden="false"]:focus {
    clip: auto; }

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat; }

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 100%/1.5 sans-serif; }

a {
  text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit; }

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; }

nav ol,
nav ul {
  list-style: none; }

small {
  font-size: 75%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: vertical; }

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

/*  http://franzheidl.github.io/bemify/ */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping */
@font-face {
  font-display: swap;
  font-family: 'DINWebLight';
  font-weight: 300;
  src: url("./fonts/DINWeb-Light.eot");
  src: url("./fonts/DINWeb-Light.woff") format("woff"), url("./fonts/DINWeb-Light.ttf") format("truetype"); }

@font-face {
  font-display: swap;
  font-family: 'DINWebRegular';
  font-weight: 400;
  src: url("./fonts/DINWeb.eot");
  src: url("./fonts/DINWeb.woff") format("woff"), url("./fonts/DINWeb.ttf") format("truetype"); }

@font-face {
  font-display: swap;
  font-family: 'DINWebMedium';
  font-weight: 500;
  src: url("./fonts/DINWeb-Medium.eot");
  src: url("./fonts/DINWeb-Medium.woff") format("woff"), url("./fonts/DINWeb-Medium.ttf") format("truetype"); }

@font-face {
  font-display: swap;
  font-family: 'DINWebBold';
  font-weight: 700;
  src: url("./fonts/DINWeb-Bold.eot");
  src: url("./fonts/DINWeb-Bold.woff") format("woff"), url("./fonts/DINWeb.ttf") format("truetype"); }

@font-face {
  font-display: swap;
  font-family: 'proxima-nova-thin';
  font-weight: 300;
  src: url("./fonts/proximanova-thin.eot");
  src: url("./fonts/proximanova-thin.woff") format("woff"), url("./fonts/proximanova-thin.ttf") format("truetype"); }

@font-face {
  font-display: swap;
  font-family: 'proxima-nova-regular';
  font-weight: 400;
  src: url("./fonts/proximanova-regular.eot");
  src: url("./fonts/proximanova-regular.woff") format("woff"), url("./fonts/proximanova-regular.ttf") format("truetype"); }

@font-face {
  font-display: swap;
  font-family: 'proxima-nova-bold';
  font-weight: 700;
  src: url("./fonts/proximanova-bold.eot");
  src: url("./fonts/proximanova-bold.woff") format("woff"), url("./fonts/proximanova-bold.ttf") format("truetype"); }

.plmr-b-din-light {
  font-family: "DINWebLight", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-din-regular {
  font-family: "DINWebRegular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-din-medium {
  font-family: "DINWebMedium", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-din-bold {
  font-family: "DINWebBold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-proxima-nova-thin {
  font-family: "proxima-nova-thin", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-proxima-nova-regular {
  font-family: "proxima-nova-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-proxima-nova-bold {
  font-family: "proxima-nova-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.plmr-b-fs-gill {
  font-family: "Gill", "Gill Sans", "Gill Sans MT", "Gill Sans Std", "Tahoma", "Trebuchet MS", sans-serif; }

.plmr-b-libre-baskerville {
  font-family: "Libre Baskerville", serif; }

@font-face {
  font-family: 'Brandon-light';
  src: url("./fonts/Brandon-light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Brandon-regular';
  src: url("./fonts/Brandon-regular.otf") format("opentype");
  font-weight: 400; }

@font-face {
  font-family: 'Brandon-medium';
  src: url("./fonts/Brandon-medium.otf") format("opentype");
  font-weight: 500; }

@font-face {
  font-family: 'Brandon-bold';
  src: url("./fonts/Brandon-bold.otf") format("opentype");
  font-weight: 700; }

@font-face {
  font-family: 'Brandon-black';
  src: url("./fonts/Brandon-black.ttf") format("truetype");
  font-weight: 900; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/**
@colordef White
@color {hex} #ffffff
@color {var} $color-white
@color {textcolor} dark
@colorof Base.Colors
*/
/**
@colordef Black
@color {hex} #000000
@color {var} $color-black
@color {textcolor} light
@colorof Base.Colors
*/
/**
@section Brand Palette
@sectionof Base.Colors
@page Brands Base
*/
/**
@colordef Primary
@color {hex} #29327c
@color {var} $color-base-brand-primary
@color {textcolor} light
@colorof Base.Colors.Brand Palette
*/
/**
@colordef Secondary
@color {hex} #32a9c1
@color {var} $color-base-brand-secondary
@color {textcolor} light
@colorof Base.Colors.Brand Palette
*/
/**
@colordef Tertiary
@color {hex} #9aca40
@color {var} $color-base-brand-tertiary
@color {textcolor} dark
@colorof Base.Colors.Brand Palette
*/
/**
@section Typography Palette
@sectionof Base.Colors
@page Brands Base
*/
/**
@section Body Text
@sectionof Base.Colors.Typography Palette
*/
/**
@colordef Primary
@color {hex} #3b3838
@color {var} $color-base-text-body-primary
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Body Text
*/
/**
@colordef Secondary
@color {hex} #535a5e
@color {var} $color-base-text-body-secondary
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Body Text
*/
/**
@colordef Tertiary
@color {hex} #2196e9
@color {var} $color-base-text-body-tertiary
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Body Text
*/
/**
@section Header Text
@sectionof Base.Colors.Typography Palette
*/
/**
@colordef Primary
@color {hex} #29327c
@color {var} $color-base-text-header-primary
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Header Text
*/
/**
@colordef Secondary
@color {hex} #535a5e
@color {var} $color-base-text-header-secondary
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Header Text
*/
/**
@colordef Tertiary
@color {hex} #2196e9
@color {var} $color-base-text-header-tertiary
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Header Text
*/
/**
@section Link
@sectionof Base.Colors.Typography Palette
*/
/**
@colordef Text Link
@color {hex} #9aca40
@color {var} $color-base-text-link
@color {textcolor} light
@colorof Base.Colors.Typography Palette.Link
*/
/**
@section Button Palette
@sectionof Base.Colors
*/
/**
@colordef Primary
@color {hex} #29327c
@color {var} $color-base-button-primary-background
@color {textcolor} light
@colorof Base.Colors.Button Palette
*/
/**
@colordef Secondary
@color {hex} #32a9c1
@color {var} $color-base-button-secondary-background
@color {textcolor} light
@colorof Base.Colors.Button Palette
*/
/**
@colordef Tertiary
@color {hex} #9aca40
@color {var} $color-base-button-tertiary-background
@color {textcolor} light
@colorof Base.Colors.Button Palette
*/
/**
@section Background Palette
@sectionof Base.Colors
@page Brands Base
*/
/**
@colordef Background Primary
@color {hex} #ffffff
@color {var} $color-base-background-primary
@color {textcolor} dark
@colorof Base.Colors.Background Palette
*/
/**
@colordef Background Secondary
@color {hex} #ededed
@color {var} $color-base-background-secondary
@color {textcolor} dark
@colorof Base.Colors.Background Palette
*/
/**
@colordef Background Tertiary
@color {hex} #f5f5f5
@color {var} $color-base-background-tertiary
@color {textcolor} dark
@colorof Base.Colors.Background Palette
*/
/**
@section Accent Palette
@sectionof Base.Colors
@page Brands Base
*/
/**
@colordef Accent Light
@color {hex} #cecfd0
@color {var} $color-base-accent-light
@color {textcolor} dark
@colorof Base.Colors.Accent Palette
*/
/**
@colordef Accent Dark
@color {hex} #2f3e48
@color {var} $color-base-accent-dark
@color {textcolor} light
@colorof Base.Colors.Accent Palette
*/
/**
@section Status Palette
@sectionof Base.Colors
@page Brands Base
*/
/**
@colordef Error
@color {hex} #dd5b4d
@color {var} $color-base-status-error
@color {textcolor} light
@colorof Base.Colors.Status Palette
*/
/**
@colordef Confirmation
@color {hex} #3dba82
@color {var} $color-base-status-confirmation
@color {textcolor} light
@colorof Base.Colors.Status Palette
*/
/**
@colordef Alert
@color {hex} #fee852
@color {var} $color-base-status-alert
@color {textcolor} dark
@colorof Base.Colors.Status Palette
*/
/**
@colordef Info
@color {hex} #2d99e6
@color {var} $color-base-status-info
@color {textcolor} light
@colorof Base.Colors.Status Palette
*/
/**
@section Product Finish Palette
@sectionof Base.Colors
@page Brands Base
*/
/**
@colordef Brushed Nickel
@color {hex} #9f9685
@color {var} $color-finish-brushed-nickel
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/
/**
@colordef Matte Black
@color {hex} #2e2e2e
@color {var} $color-finish-matte-black
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/
/**
@colordef Chrome
@color {hex} #98999d
@color {var} $color-finish-chrome
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/
/**
@colordef Bronze
@color {hex} #39312e
@color {var} $color-finish-bronze
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/
/**
@colordef Polished Nickel
@color {hex} #978a73
@color {var} $color-finish-polished-nickel
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/
/**
@colordef Spot Resist Stainless
@color {hex} #a7a69a
@color {var} $color-finish-spot-resist-stainless
@color {textcolor} light
@colorof Base.Colors.Product Finish Palette
*/
/**
@section Brand Palette
@sectionof Moen.Colors
@page Brands Moen
*/
/**
@colordef Primary
@color {hex} #5b7f95
@color {var} $color-moen-brand-primary
@color {textcolor} light
@colorof Moen.Colors.Brand Palette
*/
/**
@colordef Secondary
@color {hex} #1b365d
@color {var} $color-moen-brand-secondary
@color {textcolor} light
@colorof Moen.Colors.Brand Palette
*/
/**
@colordef Tertiary
@color {hex} #c8893b
@color {var} $color-moen-brand-tertiary
@color {textcolor} light
@colorof Moen.Colors.Brand Palette
*/
/**
@section Typography Palette
@sectionof Moen.Colors
@page Brands Moen
*/
/**
@section Body Text
@sectionof Moen.Colors.Typography Palette
*/
/**
@colordef Primary
@color {hex} #1f1f1f
@color {var} $color-moen-text-body-primary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Body Text
*/
/**
@colordef Secondary
@color {hex} #8b888c
@color {var} $color-moen-text-body-secondary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Body Text
*/
/**
@colordef Tertiary
@color {hex} #19345e
@color {var} $color-moen-text-body-tertiary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Body Text
*/
/**
@section Header Text
@sectionof Moen.Colors.Typography Palette
*/
/**
@colordef Primary
@color {hex} #5b7f95
@color {var} $color-moen-text-header-primary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Header Text
*/
/**
@colordef Secondary
@color {hex} #19345e
@color {var} $color-moen-text-header-secondary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Header Text
*/
/**
@colordef Tertiary
@color {hex} #c8893b
@color {var} $color-moen-text-header-tertiary
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Header Text
*/
/**
@section Link
@sectionof Moen.Colors.Typography Palette
*/
/**
@colordef Text Link
@color {hex} #c8893b
@color {var} $color-moen-text-link
@color {textcolor} light
@colorof Moen.Colors.Typography Palette.Link
*/
/**
@section Button Palette
@sectionof Moen.Colors
@page Brands Moen
*/
/**
@colordef Primary
@color {hex} #5b7f95
@color {var} $color-moen-button-primary
@color {textcolor} light
@colorof Moen.Colors.Button Palette
*/
/**
@colordef Secondary
@color {hex} #1b365d
@color {var} $color-moen-button-secondary
@color {textcolor} light
@colorof Moen.Colors.Button Palette
*/
/**
@colordef Tertiary
@color {hex} #c8893b
@color {var} $color-moen-button-tertiary
@color {textcolor} light
@colorof Moen.Colors.Button Palette
*/
/**
@section Background Palette
@sectionof Moen.Colors
@page Brands Moen
*/
/**
@colordef Background Primary
@color {hex} #ffffff
@color {var} $color-moen-background-primary
@color {textcolor} dark
@colorof Moen.Colors.Background Palette
*/
/**
@colordef Background Secondary
@color {hex} #ecedef
@color {var} $color-moen-background-secondary
@color {textcolor} dark
@colorof Moen.Colors.Background Palette
*/
/**
@colordef Background Tertiary
@color {hex} #f6f6f6
@color {var} $color-moen-background-tertiary
@color {textcolor} dark
@colorof Moen.Colors.Background Palette
*/
/**
@section Accent Palette
@sectionof Moen.Colors
@page Brands Moen
*/
/**
@colordef Accent Primary
@color {hex} #d8d8d8
@color {var} $color-moen-accent-light
@color {textcolor} dark
@colorof Moen.Colors.Accent Palette
*/
/**
@colordef Accent Secondary
@color {hex} #8b888c
@color {var} $color-moen-accent-dark
@color {textcolor} light
@colorof Moen.Colors.Accent Palette
*/
/**
@section Status Palette
@sectionof Moen.Colors
@page Brands Moen
*/
/**
@colordef Status Primary
@color {hex} #d50001
@color {var} $color-moen-status-error
@color {textcolor} light
@colorof Moen.Colors.Status Palette
*/
.fill__gradient--bright-gray {
  background-image: -webkit-linear-gradient( top , #e5e5e5, #fff);
  background-image: linear-gradient(to bottom, #e5e5e5, #fff); }

/*
 *
 *  http://www.sitepoint.com/better-solution-managing-z-index-sass/
 *
 *  http://www.smashingmagazine.com/2014/06/12/sassy-z-index-management-for-complex-layouts/
 *  http://viget.com/extend/sass-maps-and-z-indexes
 *  http://css-tricks.com/handling-z-index/
 *
 */
/*
// Examples & demo
// ---

// Modal
.modal {
  z-index: z("modal");
}

// ... and its backdrop
// Computed z-index based on the one from modal
.modal-backdrop {
  z-index: z("modal") + 1;
}

// Dropdown
.dropdown {
  z-index: z("dropdown");
}

// A pseudo-element that has to be on top
// Computed z-index based on the default one
.element:after {
  z-index: z("default") - 2;
}

// A component that has to be veryyyy low
.im-falling {
  z-index: z("bottomless-pit");
}

// Calling an unknown layer
// will omit the z-index property
.error {
  z-index: z("unknown-z-index");
}
*/
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

.animate--bounce {
  animation: bounce 2s infinite; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* WasteKing Color Palette */
.bg--wk-maroon {
  background-color: #802428; }

.section-rule--red {
  border-color: #802428; }

.button--search {
  background-image: url("./images/magnifying-glass-red_thin.svg"); }

.button--cta {
  background-color: #802428;
  font-size: 0.8125em; }
  .button--cta:hover {
    background-color: #641215; }

.button--arrow-right {
  background-image: url("./images/icon-right-open_red.svg");
  min-width: 19px;
  border: none;
  height: 19px;
  width: 19px;
  position: relative;
  top: -2px;
  background-repeat: no-repeat; }

.button--teal-hover {
  padding: .5em;
  border: none;
  color: #427082; }
  .button--teal-hover:hover, .button--teal-hover:active, .button--teal-hover:focus {
    background-color: #427082;
    color: #fff; }

.button--secondary {
  box-shadow: none;
  background-color: #802428;
  border-color: transparent;
  border-radius: 0;
  padding: 14px 30px;
  font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase; }
  .button--secondary:hover, .button--secondary:active, .button--secondary:focus {
    background-color: #641215;
    text-decoration: none !important; }

.button--default-border {
  color: #fff;
  border-color: #fff; }

.form-select--red {
  background-color: #802428;
  border: none;
  position: relative; }
  .form-select--red .form-select {
    appearance: none;
    background-color: #802428;
    border-radius: 0;
    color: #fff;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    padding: 14px .375em 14px 1em;
    border: none; }
    .form-select--red .form-select::-ms-expand {
      display: none; }
  .form-select--red:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 0.3125em solid transparent;
    border-right: 0.3125em solid transparent;
    border-top: 0.3125em solid #fff;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: .5em; }

.form-select--red-border {
  background-color: #802428;
  border: none;
  position: relative;
  border: 1px solid #fff; }
  .form-select--red-border .form-select {
    appearance: none;
    background-color: #802428;
    border-radius: 0;
    color: #fff;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    padding: 14px .375em 14px 1em;
    border: none; }
    .form-select--red-border .form-select::-ms-expand {
      display: none; }
  .form-select--red-border:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 0.3125em solid transparent;
    border-right: 0.3125em solid transparent;
    border-top: 0.3125em solid #fff;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: .5em; }
  .form-select--red-border .form-select {
    height: 100%; }

@font-face {
  font-family: 'Brandon-light';
  src: url("./fonts/Brandon-light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Brandon-regular';
  src: url("./fonts/Brandon-regular.otf") format("opentype");
  font-weight: 400; }

@font-face {
  font-family: 'Brandon-medium';
  src: url("./fonts/Brandon-medium.otf") format("opentype");
  font-weight: 500; }

@font-face {
  font-family: 'Brandon-bold';
  src: url("./fonts/Brandon-bold.otf") format("opentype");
  font-weight: 700; }

@font-face {
  font-family: 'Brandon-black';
  src: url("./fonts/Brandon-black.ttf") format("truetype");
  font-weight: 900; }

body {
  color: #56565C;
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  line-height: 1.2; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

ul {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

p {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.is-block {
  display: block; }

.has-centered {
  text-align: center; }

.iframe__wrapper {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden; }
  .iframe__wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.is-hidden {
  display: none !important; }

.no-wrap {
  white-space: nowrap; }

.no-pointer {
  cursor: default; }

[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer; }

[ng-click].no-pointer,
[data-ng-click].no-pointer,
[x-ng-click].no-pointer {
  cursor: default; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.form__error-message .ng-active {
  opacity: 1; }

.form__error-message .ng-inactive {
  opacity: 0; }

.xxxs--block,
.xxxs--inline,
.xxxs--inline-block,
.xxs--block,
.xxs--inline,
.xxs--inline-block,
.xs--block,
.xs--inline,
.xs--inline-block,
.sm--block,
.sm--inline,
.sm--inline-block,
.md--block,
.md--inline,
.md--inline-block,
.lg--block,
.lg--inline,
.lg--inline-block,
.xl--block,
.xl--inline,
.xl--inline-block {
  display: none !important; }

@media screen and (min-width: 0rem) and (max-width: 19.9375rem) {
  .xxxs--block {
    display: block !important; }
  .xxxs--inline {
    display: inline !important; }
  .xxxs--inline-block {
    display: inline-block !important; }
  .xxxs--hidden {
    display: none !important; } }

@media screen and (min-width: 20rem) and (max-width: 27.9375rem) {
  .xxs--block {
    display: block !important; }
  .xxs--inline {
    display: inline !important; }
  .xxs--inline-block {
    display: inline-block !important; }
  .xxs--hidden {
    display: none !important; } }

@media screen and (min-width: 28rem) and (max-width: 33.9375rem) {
  .xs--block {
    display: block !important; }
  .xs--inline {
    display: inline !important; }
  .xs--inline-block {
    display: inline-block !important; }
  .xs--hidden {
    display: none !important; } }

@media screen and (min-width: 34rem) and (max-width: 47.9375rem) {
  .sm--block {
    display: block !important; }
  .sm--inline {
    display: inline !important; }
  .sm--inline-block {
    display: inline-block !important; }
  .sm--hidden {
    display: none !important; } }

@media screen and (min-width: 48rem) and (max-width: 61.9375rem) {
  .md--block {
    display: block !important; }
  .md--inline {
    display: inline !important; }
  .md--inline-block {
    display: inline-block !important; }
  .md--hidden {
    display: none !important; } }

@media screen and (min-width: 62rem) and (max-width: 74.9375rem) {
  .lg--block {
    display: block !important; }
  .lg--inline {
    display: inline !important; }
  .lg--inline-block {
    display: inline-block !important; }
  .lg--hidden {
    display: none !important; } }

@media screen and (min-width: 75rem) {
  .xl--block {
    display: block !important; }
  .xl--inline {
    display: inline !important; }
  .xl--inline-block {
    display: inline-block !important; }
  .xl--hidden {
    display: none !important; } }

/*
Padding Styling

Padding utility classes that can be added to element. Padding numbers are 5 through 150 in increments of 5.
*<em><strong>class sg-placeholder is for Styleguide use only</strong></em>

markup:
<div class="sg-placeholder padding--25">
  Padding 25px on all sides
</div>
<br />
<div class="sg-placeholder padding--top-25">
  Padding top 25px
</div>
<br />
<div class="sg-placeholder padding--bottom-25">
  Padding bottom 25px
</div>
<br />
<div class="sg-placeholder padding--top-bottom-25">
  Padding 25px on top and bottom
</div>
<br />
<div class="sg-placeholder padding--left-right-25">
  Padding 25px on left and right
</div>

Styleguide 9.1
*/
.padding--5 {
  padding: 5px; }

.padding--top-bottom-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding--left-right-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding--bottom-5 {
  padding-bottom: 5px; }

.padding--top-5 {
  padding-top: 5px; }

.padding--10 {
  padding: 10px; }

.padding--top-bottom-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding--left-right-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding--bottom-10 {
  padding-bottom: 10px; }

.padding--top-10 {
  padding-top: 10px; }

.padding--15 {
  padding: 15px; }

.padding--top-bottom-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding--left-right-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding--bottom-15 {
  padding-bottom: 15px; }

.padding--top-15 {
  padding-top: 15px; }

.padding--20 {
  padding: 20px; }

.padding--top-bottom-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding--left-right-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding--bottom-20 {
  padding-bottom: 20px; }

.padding--top-20 {
  padding-top: 20px; }

.padding--25 {
  padding: 25px; }

.padding--top-bottom-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding--left-right-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding--bottom-25 {
  padding-bottom: 25px; }

.padding--top-25 {
  padding-top: 25px; }

.padding--30 {
  padding: 30px; }

.padding--top-bottom-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding--left-right-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding--bottom-30 {
  padding-bottom: 30px; }

.padding--top-30 {
  padding-top: 30px; }

.padding--35 {
  padding: 35px; }

.padding--top-bottom-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding--left-right-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding--bottom-35 {
  padding-bottom: 35px; }

.padding--top-35 {
  padding-top: 35px; }

.padding--40 {
  padding: 40px; }

.padding--top-bottom-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding--left-right-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding--bottom-40 {
  padding-bottom: 40px; }

.padding--top-40 {
  padding-top: 40px; }

.padding--45 {
  padding: 45px; }

.padding--top-bottom-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding--left-right-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding--bottom-45 {
  padding-bottom: 45px; }

.padding--top-45 {
  padding-top: 45px; }

.padding--50 {
  padding: 50px; }

.padding--top-bottom-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding--left-right-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding--bottom-50 {
  padding-bottom: 50px; }

.padding--top-50 {
  padding-top: 50px; }

.padding--55 {
  padding: 55px; }

.padding--top-bottom-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding--left-right-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding--bottom-55 {
  padding-bottom: 55px; }

.padding--top-55 {
  padding-top: 55px; }

.padding--60 {
  padding: 60px; }

.padding--top-bottom-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding--left-right-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding--bottom-60 {
  padding-bottom: 60px; }

.padding--top-60 {
  padding-top: 60px; }

.padding--65 {
  padding: 65px; }

.padding--top-bottom-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding--left-right-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding--bottom-65 {
  padding-bottom: 65px; }

.padding--top-65 {
  padding-top: 65px; }

.padding--70 {
  padding: 70px; }

.padding--top-bottom-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding--left-right-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding--bottom-70 {
  padding-bottom: 70px; }

.padding--top-70 {
  padding-top: 70px; }

.padding--75 {
  padding: 75px; }

.padding--top-bottom-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding--left-right-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding--bottom-75 {
  padding-bottom: 75px; }

.padding--top-75 {
  padding-top: 75px; }

.padding--80 {
  padding: 80px; }

.padding--top-bottom-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding--left-right-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding--bottom-80 {
  padding-bottom: 80px; }

.padding--top-80 {
  padding-top: 80px; }

.padding--85 {
  padding: 85px; }

.padding--top-bottom-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding--left-right-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding--bottom-85 {
  padding-bottom: 85px; }

.padding--top-85 {
  padding-top: 85px; }

.padding--90 {
  padding: 90px; }

.padding--top-bottom-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding--left-right-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding--bottom-90 {
  padding-bottom: 90px; }

.padding--top-90 {
  padding-top: 90px; }

.padding--95 {
  padding: 95px; }

.padding--top-bottom-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding--left-right-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding--bottom-95 {
  padding-bottom: 95px; }

.padding--top-95 {
  padding-top: 95px; }

.padding--100 {
  padding: 100px; }

.padding--top-bottom-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding--left-right-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding--bottom-100 {
  padding-bottom: 100px; }

.padding--top-100 {
  padding-top: 100px; }

.padding--105 {
  padding: 105px; }

.padding--top-bottom-105 {
  padding-top: 105px;
  padding-bottom: 105px; }

.padding--left-right-105 {
  padding-left: 105px;
  padding-right: 105px; }

.padding--bottom-105 {
  padding-bottom: 105px; }

.padding--top-105 {
  padding-top: 105px; }

.padding--110 {
  padding: 110px; }

.padding--top-bottom-110 {
  padding-top: 110px;
  padding-bottom: 110px; }

.padding--left-right-110 {
  padding-left: 110px;
  padding-right: 110px; }

.padding--bottom-110 {
  padding-bottom: 110px; }

.padding--top-110 {
  padding-top: 110px; }

.padding--115 {
  padding: 115px; }

.padding--top-bottom-115 {
  padding-top: 115px;
  padding-bottom: 115px; }

.padding--left-right-115 {
  padding-left: 115px;
  padding-right: 115px; }

.padding--bottom-115 {
  padding-bottom: 115px; }

.padding--top-115 {
  padding-top: 115px; }

.padding--120 {
  padding: 120px; }

.padding--top-bottom-120 {
  padding-top: 120px;
  padding-bottom: 120px; }

.padding--left-right-120 {
  padding-left: 120px;
  padding-right: 120px; }

.padding--bottom-120 {
  padding-bottom: 120px; }

.padding--top-120 {
  padding-top: 120px; }

.padding--125 {
  padding: 125px; }

.padding--top-bottom-125 {
  padding-top: 125px;
  padding-bottom: 125px; }

.padding--left-right-125 {
  padding-left: 125px;
  padding-right: 125px; }

.padding--bottom-125 {
  padding-bottom: 125px; }

.padding--top-125 {
  padding-top: 125px; }

.padding--130 {
  padding: 130px; }

.padding--top-bottom-130 {
  padding-top: 130px;
  padding-bottom: 130px; }

.padding--left-right-130 {
  padding-left: 130px;
  padding-right: 130px; }

.padding--bottom-130 {
  padding-bottom: 130px; }

.padding--top-130 {
  padding-top: 130px; }

.padding--135 {
  padding: 135px; }

.padding--top-bottom-135 {
  padding-top: 135px;
  padding-bottom: 135px; }

.padding--left-right-135 {
  padding-left: 135px;
  padding-right: 135px; }

.padding--bottom-135 {
  padding-bottom: 135px; }

.padding--top-135 {
  padding-top: 135px; }

.padding--140 {
  padding: 140px; }

.padding--top-bottom-140 {
  padding-top: 140px;
  padding-bottom: 140px; }

.padding--left-right-140 {
  padding-left: 140px;
  padding-right: 140px; }

.padding--bottom-140 {
  padding-bottom: 140px; }

.padding--top-140 {
  padding-top: 140px; }

.padding--145 {
  padding: 145px; }

.padding--top-bottom-145 {
  padding-top: 145px;
  padding-bottom: 145px; }

.padding--left-right-145 {
  padding-left: 145px;
  padding-right: 145px; }

.padding--bottom-145 {
  padding-bottom: 145px; }

.padding--top-145 {
  padding-top: 145px; }

.padding--150 {
  padding: 150px; }

.padding--top-bottom-150 {
  padding-top: 150px;
  padding-bottom: 150px; }

.padding--left-right-150 {
  padding-left: 150px;
  padding-right: 150px; }

.padding--bottom-150 {
  padding-bottom: 150px; }

.padding--top-150 {
  padding-top: 150px; }

.margin--5 {
  margin: 5px; }

.margin--top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin--left-right-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin--bottom-5 {
  margin-bottom: 5px; }

.margin--top-5 {
  margin-top: 5px; }

.margin--10 {
  margin: 10px; }

.margin--top-bottom-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin--left-right-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin--bottom-10 {
  margin-bottom: 10px; }

.margin--top-10 {
  margin-top: 10px; }

.margin--15 {
  margin: 15px; }

.margin--top-bottom-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin--left-right-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin--bottom-15 {
  margin-bottom: 15px; }

.margin--top-15 {
  margin-top: 15px; }

.margin--20 {
  margin: 20px; }

.margin--top-bottom-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin--left-right-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin--bottom-20 {
  margin-bottom: 20px; }

.margin--top-20 {
  margin-top: 20px; }

.margin--25 {
  margin: 25px; }

.margin--top-bottom-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin--left-right-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin--bottom-25 {
  margin-bottom: 25px; }

.margin--top-25 {
  margin-top: 25px; }

.margin--30 {
  margin: 30px; }

.margin--top-bottom-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin--left-right-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin--bottom-30 {
  margin-bottom: 30px; }

.margin--top-30 {
  margin-top: 30px; }

.margin--35 {
  margin: 35px; }

.margin--top-bottom-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin--left-right-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin--bottom-35 {
  margin-bottom: 35px; }

.margin--top-35 {
  margin-top: 35px; }

.margin--40 {
  margin: 40px; }

.margin--top-bottom-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin--left-right-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin--bottom-40 {
  margin-bottom: 40px; }

.margin--top-40 {
  margin-top: 40px; }

.margin--45 {
  margin: 45px; }

.margin--top-bottom-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin--left-right-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin--bottom-45 {
  margin-bottom: 45px; }

.margin--top-45 {
  margin-top: 45px; }

.margin--50 {
  margin: 50px; }

.margin--top-bottom-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin--left-right-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin--bottom-50 {
  margin-bottom: 50px; }

.margin--top-50 {
  margin-top: 50px; }

.margin--55 {
  margin: 55px; }

.margin--top-bottom-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin--left-right-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin--bottom-55 {
  margin-bottom: 55px; }

.margin--top-55 {
  margin-top: 55px; }

.margin--60 {
  margin: 60px; }

.margin--top-bottom-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin--left-right-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin--bottom-60 {
  margin-bottom: 60px; }

.margin--top-60 {
  margin-top: 60px; }

.margin--65 {
  margin: 65px; }

.margin--top-bottom-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin--left-right-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin--bottom-65 {
  margin-bottom: 65px; }

.margin--top-65 {
  margin-top: 65px; }

.margin--70 {
  margin: 70px; }

.margin--top-bottom-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin--left-right-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin--bottom-70 {
  margin-bottom: 70px; }

.margin--top-70 {
  margin-top: 70px; }

.margin--75 {
  margin: 75px; }

.margin--top-bottom-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin--left-right-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin--bottom-75 {
  margin-bottom: 75px; }

.margin--top-75 {
  margin-top: 75px; }

.margin--80 {
  margin: 80px; }

.margin--top-bottom-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin--left-right-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin--bottom-80 {
  margin-bottom: 80px; }

.margin--top-80 {
  margin-top: 80px; }

.margin--85 {
  margin: 85px; }

.margin--top-bottom-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin--left-right-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin--bottom-85 {
  margin-bottom: 85px; }

.margin--top-85 {
  margin-top: 85px; }

.margin--90 {
  margin: 90px; }

.margin--top-bottom-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin--left-right-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin--bottom-90 {
  margin-bottom: 90px; }

.margin--top-90 {
  margin-top: 90px; }

.margin--95 {
  margin: 95px; }

.margin--top-bottom-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin--left-right-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin--bottom-95 {
  margin-bottom: 95px; }

.margin--top-95 {
  margin-top: 95px; }

.margin--100 {
  margin: 100px; }

.margin--top-bottom-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin--left-right-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin--bottom-100 {
  margin-bottom: 100px; }

.margin--top-100 {
  margin-top: 100px; }

.margin--105 {
  margin: 105px; }

.margin--top-bottom-105 {
  margin-top: 105px;
  margin-bottom: 105px; }

.margin--left-right-105 {
  margin-left: 105px;
  margin-right: 105px; }

.margin--bottom-105 {
  margin-bottom: 105px; }

.margin--top-105 {
  margin-top: 105px; }

.margin--110 {
  margin: 110px; }

.margin--top-bottom-110 {
  margin-top: 110px;
  margin-bottom: 110px; }

.margin--left-right-110 {
  margin-left: 110px;
  margin-right: 110px; }

.margin--bottom-110 {
  margin-bottom: 110px; }

.margin--top-110 {
  margin-top: 110px; }

.margin--115 {
  margin: 115px; }

.margin--top-bottom-115 {
  margin-top: 115px;
  margin-bottom: 115px; }

.margin--left-right-115 {
  margin-left: 115px;
  margin-right: 115px; }

.margin--bottom-115 {
  margin-bottom: 115px; }

.margin--top-115 {
  margin-top: 115px; }

.margin--120 {
  margin: 120px; }

.margin--top-bottom-120 {
  margin-top: 120px;
  margin-bottom: 120px; }

.margin--left-right-120 {
  margin-left: 120px;
  margin-right: 120px; }

.margin--bottom-120 {
  margin-bottom: 120px; }

.margin--top-120 {
  margin-top: 120px; }

.margin--125 {
  margin: 125px; }

.margin--top-bottom-125 {
  margin-top: 125px;
  margin-bottom: 125px; }

.margin--left-right-125 {
  margin-left: 125px;
  margin-right: 125px; }

.margin--bottom-125 {
  margin-bottom: 125px; }

.margin--top-125 {
  margin-top: 125px; }

.margin--130 {
  margin: 130px; }

.margin--top-bottom-130 {
  margin-top: 130px;
  margin-bottom: 130px; }

.margin--left-right-130 {
  margin-left: 130px;
  margin-right: 130px; }

.margin--bottom-130 {
  margin-bottom: 130px; }

.margin--top-130 {
  margin-top: 130px; }

.margin--135 {
  margin: 135px; }

.margin--top-bottom-135 {
  margin-top: 135px;
  margin-bottom: 135px; }

.margin--left-right-135 {
  margin-left: 135px;
  margin-right: 135px; }

.margin--bottom-135 {
  margin-bottom: 135px; }

.margin--top-135 {
  margin-top: 135px; }

.margin--140 {
  margin: 140px; }

.margin--top-bottom-140 {
  margin-top: 140px;
  margin-bottom: 140px; }

.margin--left-right-140 {
  margin-left: 140px;
  margin-right: 140px; }

.margin--bottom-140 {
  margin-bottom: 140px; }

.margin--top-140 {
  margin-top: 140px; }

.margin--145 {
  margin: 145px; }

.margin--top-bottom-145 {
  margin-top: 145px;
  margin-bottom: 145px; }

.margin--left-right-145 {
  margin-left: 145px;
  margin-right: 145px; }

.margin--bottom-145 {
  margin-bottom: 145px; }

.margin--top-145 {
  margin-top: 145px; }

.margin--150 {
  margin: 150px; }

.margin--top-bottom-150 {
  margin-top: 150px;
  margin-bottom: 150px; }

.margin--left-right-150 {
  margin-left: 150px;
  margin-right: 150px; }

.margin--bottom-150 {
  margin-bottom: 150px; }

.margin--top-150 {
  margin-top: 150px; }

.bleed {
  position: relative; }
  .bleed::before, .bleed::after {
    background-color: inherit;
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    width: calc(50vw - 50%);
    height: 100%;
    pointer-events: none; }
  .bleed::before {
    left: 0;
    transform: translateX(-100%); }
  .bleed::after {
    right: 0;
    transform: translateX(100%); }

.overflow-hidden {
  overflow: hidden; }

/*
 *  http://thoughtbot.github.io/neat-docs/latest/
 *  http://culttt.com/2013/12/09/adding-bourbon-and-bourbon-neat-with-bower/
 *
 *  Grid functionality is defined with mixins and functions in this file; defaults are stored in the settings/neat-grid
 *
 */
/*
Grid

Grid is built using Bourbon Neat, using the familiar Bootstrap 4 class naming conventions.

Styleguide 10
*/
/*
Base Grid Example

This is just an example to make sure the build works with Bourbon Neat.

markup:
<div class="container">
<div class="row">
  <div class="child col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">1</div>
  <div class="child col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">2</div>
</div>
<div class="row">
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">3</div>
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">4</div>
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">5</div>
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">6</div>
</div>
<div class="row">
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">3</div>
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">4</div>
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">5</div>
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">6</div>
</div>
<div class="row">
  <div class="child col-xl-1 col-lg-2 col-md-3 col-sm-2 col-xs-3">7</div>
  <div class="child col-xl-2 col-lg-4 col-md-3 col-sm-5 col-xs-4">8</div>
  <div class="child col-xl-3 col-lg-4 col-md-3 col-sm-2 col-xs-3">9</div>
  <div class="child col-xl-6 col-lg-2 col-md-3 col-sm-3 col-xs-2">10</div>
</div>
</div>

Styleguide 10.1
*/
.child {
  padding: 1em;
  font-weight: bold;
  height: 200px;
  background-color: #fff;
  border: 1px solid #8b8d8e;
  margin-bottom: 1.25rem;
  padding: 1rem; }

.row {
  display: block; }
  .row::after {
    clear: both;
    content: "";
    display: block; }

@media (min-width: 0rem) {
  .col-xxxs-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-xxxs-1:last-child {
      margin-right: 0; }
  .col-xxxs-push-1 {
    margin-left: 8.5298%; }
  .col-xxxs-pull-1 {
    margin-left: -8.5298%; }
  .col-xxxs-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-xxxs-2:last-child {
      margin-right: 0; }
  .col-xxxs-push-2 {
    margin-left: 17.05961%; }
  .col-xxxs-pull-2 {
    margin-left: -17.05961%; }
  .col-xxxs-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-xxxs-3:last-child {
      margin-right: 0; }
  .col-xxxs-push-3 {
    margin-left: 25.58941%; }
  .col-xxxs-pull-3 {
    margin-left: -25.58941%; }
  .col-xxxs-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-xxxs-4:last-child {
      margin-right: 0; }
  .col-xxxs-push-4 {
    margin-left: 34.11922%; }
  .col-xxxs-pull-4 {
    margin-left: -34.11922%; }
  .col-xxxs-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-xxxs-5:last-child {
      margin-right: 0; }
  .col-xxxs-push-5 {
    margin-left: 42.64902%; }
  .col-xxxs-pull-5 {
    margin-left: -42.64902%; }
  .col-xxxs-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-xxxs-6:last-child {
      margin-right: 0; }
  .col-xxxs-push-6 {
    margin-left: 51.17883%; }
  .col-xxxs-pull-6 {
    margin-left: -51.17883%; }
  .col-xxxs-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-xxxs-7:last-child {
      margin-right: 0; }
  .col-xxxs-push-7 {
    margin-left: 59.70863%; }
  .col-xxxs-pull-7 {
    margin-left: -59.70863%; }
  .col-xxxs-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-xxxs-8:last-child {
      margin-right: 0; }
  .col-xxxs-push-8 {
    margin-left: 68.23843%; }
  .col-xxxs-pull-8 {
    margin-left: -68.23843%; }
  .col-xxxs-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-xxxs-9:last-child {
      margin-right: 0; }
  .col-xxxs-push-9 {
    margin-left: 76.76824%; }
  .col-xxxs-pull-9 {
    margin-left: -76.76824%; }
  .col-xxxs-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-xxxs-10:last-child {
      margin-right: 0; }
  .col-xxxs-push-10 {
    margin-left: 85.29804%; }
  .col-xxxs-pull-10 {
    margin-left: -85.29804%; }
  .col-xxxs-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-xxxs-11:last-child {
      margin-right: 0; }
  .col-xxxs-push-11 {
    margin-left: 93.82785%; }
  .col-xxxs-pull-11 {
    margin-left: -93.82785%; }
  .col-xxxs-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-xxxs-12:last-child {
      margin-right: 0; }
  .col-xxxs-push-12 {
    margin-left: 102.35765%; }
  .col-xxxs-pull-12 {
    margin-left: -102.35765%; }
  .xxxs--omega {
    margin-right: 0; }
  .xxxs--bottom-margin {
    margin-bottom: 2em; }
  .xxxs--top-margin {
    margin-top: 2em; }
  .xxxs--float-left {
    float: left; }
  .xxxs--float-right {
    float: right; }
  .xxxs--text-center {
    text-align: center; }
  .xxxs--text-left {
    text-align: left; }
  .xxxs--text-right {
    text-align: right; } }

@media (min-width: 20rem) {
  .col-xxs-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-xxs-1:last-child {
      margin-right: 0; }
  .col-xxs-push-1 {
    margin-left: 8.5298%; }
  .col-xxs-pull-1 {
    margin-left: -8.5298%; }
  .col-xxs-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-xxs-2:last-child {
      margin-right: 0; }
  .col-xxs-push-2 {
    margin-left: 17.05961%; }
  .col-xxs-pull-2 {
    margin-left: -17.05961%; }
  .col-xxs-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-xxs-3:last-child {
      margin-right: 0; }
  .col-xxs-push-3 {
    margin-left: 25.58941%; }
  .col-xxs-pull-3 {
    margin-left: -25.58941%; }
  .col-xxs-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-xxs-4:last-child {
      margin-right: 0; }
  .col-xxs-push-4 {
    margin-left: 34.11922%; }
  .col-xxs-pull-4 {
    margin-left: -34.11922%; }
  .col-xxs-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-xxs-5:last-child {
      margin-right: 0; }
  .col-xxs-push-5 {
    margin-left: 42.64902%; }
  .col-xxs-pull-5 {
    margin-left: -42.64902%; }
  .col-xxs-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-xxs-6:last-child {
      margin-right: 0; }
  .col-xxs-push-6 {
    margin-left: 51.17883%; }
  .col-xxs-pull-6 {
    margin-left: -51.17883%; }
  .col-xxs-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-xxs-7:last-child {
      margin-right: 0; }
  .col-xxs-push-7 {
    margin-left: 59.70863%; }
  .col-xxs-pull-7 {
    margin-left: -59.70863%; }
  .col-xxs-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-xxs-8:last-child {
      margin-right: 0; }
  .col-xxs-push-8 {
    margin-left: 68.23843%; }
  .col-xxs-pull-8 {
    margin-left: -68.23843%; }
  .col-xxs-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-xxs-9:last-child {
      margin-right: 0; }
  .col-xxs-push-9 {
    margin-left: 76.76824%; }
  .col-xxs-pull-9 {
    margin-left: -76.76824%; }
  .col-xxs-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-xxs-10:last-child {
      margin-right: 0; }
  .col-xxs-push-10 {
    margin-left: 85.29804%; }
  .col-xxs-pull-10 {
    margin-left: -85.29804%; }
  .col-xxs-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-xxs-11:last-child {
      margin-right: 0; }
  .col-xxs-push-11 {
    margin-left: 93.82785%; }
  .col-xxs-pull-11 {
    margin-left: -93.82785%; }
  .col-xxs-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-xxs-12:last-child {
      margin-right: 0; }
  .col-xxs-push-12 {
    margin-left: 102.35765%; }
  .col-xxs-pull-12 {
    margin-left: -102.35765%; }
  .xxs--omega {
    margin-right: 0; }
  .xxs--bottom-margin {
    margin-bottom: 2em; }
  .xxs--top-margin {
    margin-top: 2em; }
  .xxs--float-left {
    float: left; }
  .xxs--float-right {
    float: right; }
  .xxs--text-center {
    text-align: center; }
  .xxs--text-left {
    text-align: left; }
  .xxs--text-right {
    text-align: right; } }

@media (min-width: 28rem) {
  .col-xs-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-xs-1:last-child {
      margin-right: 0; }
  .col-xs-push-1 {
    margin-left: 8.5298%; }
  .col-xs-pull-1 {
    margin-left: -8.5298%; }
  .col-xs-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-xs-2:last-child {
      margin-right: 0; }
  .col-xs-push-2 {
    margin-left: 17.05961%; }
  .col-xs-pull-2 {
    margin-left: -17.05961%; }
  .col-xs-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-xs-3:last-child {
      margin-right: 0; }
  .col-xs-push-3 {
    margin-left: 25.58941%; }
  .col-xs-pull-3 {
    margin-left: -25.58941%; }
  .col-xs-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-xs-4:last-child {
      margin-right: 0; }
  .col-xs-push-4 {
    margin-left: 34.11922%; }
  .col-xs-pull-4 {
    margin-left: -34.11922%; }
  .col-xs-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-xs-5:last-child {
      margin-right: 0; }
  .col-xs-push-5 {
    margin-left: 42.64902%; }
  .col-xs-pull-5 {
    margin-left: -42.64902%; }
  .col-xs-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-xs-6:last-child {
      margin-right: 0; }
  .col-xs-push-6 {
    margin-left: 51.17883%; }
  .col-xs-pull-6 {
    margin-left: -51.17883%; }
  .col-xs-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-xs-7:last-child {
      margin-right: 0; }
  .col-xs-push-7 {
    margin-left: 59.70863%; }
  .col-xs-pull-7 {
    margin-left: -59.70863%; }
  .col-xs-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-xs-8:last-child {
      margin-right: 0; }
  .col-xs-push-8 {
    margin-left: 68.23843%; }
  .col-xs-pull-8 {
    margin-left: -68.23843%; }
  .col-xs-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-xs-9:last-child {
      margin-right: 0; }
  .col-xs-push-9 {
    margin-left: 76.76824%; }
  .col-xs-pull-9 {
    margin-left: -76.76824%; }
  .col-xs-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-xs-10:last-child {
      margin-right: 0; }
  .col-xs-push-10 {
    margin-left: 85.29804%; }
  .col-xs-pull-10 {
    margin-left: -85.29804%; }
  .col-xs-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-xs-11:last-child {
      margin-right: 0; }
  .col-xs-push-11 {
    margin-left: 93.82785%; }
  .col-xs-pull-11 {
    margin-left: -93.82785%; }
  .col-xs-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-xs-12:last-child {
      margin-right: 0; }
  .col-xs-push-12 {
    margin-left: 102.35765%; }
  .col-xs-pull-12 {
    margin-left: -102.35765%; }
  .xs--omega {
    margin-right: 0; }
  .xs--bottom-margin {
    margin-bottom: 2em; }
  .xs--top-margin {
    margin-top: 2em; }
  .xs--float-left {
    float: left; }
  .xs--float-right {
    float: right; }
  .xs--text-center {
    text-align: center; }
  .xs--text-left {
    text-align: left; }
  .xs--text-right {
    text-align: right; } }

@media (min-width: 34rem) {
  .col-sm-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-sm-1:last-child {
      margin-right: 0; }
  .col-sm-push-1 {
    margin-left: 8.5298%; }
  .col-sm-pull-1 {
    margin-left: -8.5298%; }
  .col-sm-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-sm-2:last-child {
      margin-right: 0; }
  .col-sm-push-2 {
    margin-left: 17.05961%; }
  .col-sm-pull-2 {
    margin-left: -17.05961%; }
  .col-sm-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-sm-3:last-child {
      margin-right: 0; }
  .col-sm-push-3 {
    margin-left: 25.58941%; }
  .col-sm-pull-3 {
    margin-left: -25.58941%; }
  .col-sm-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-sm-4:last-child {
      margin-right: 0; }
  .col-sm-push-4 {
    margin-left: 34.11922%; }
  .col-sm-pull-4 {
    margin-left: -34.11922%; }
  .col-sm-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-sm-5:last-child {
      margin-right: 0; }
  .col-sm-push-5 {
    margin-left: 42.64902%; }
  .col-sm-pull-5 {
    margin-left: -42.64902%; }
  .col-sm-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-sm-6:last-child {
      margin-right: 0; }
  .col-sm-push-6 {
    margin-left: 51.17883%; }
  .col-sm-pull-6 {
    margin-left: -51.17883%; }
  .col-sm-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-sm-7:last-child {
      margin-right: 0; }
  .col-sm-push-7 {
    margin-left: 59.70863%; }
  .col-sm-pull-7 {
    margin-left: -59.70863%; }
  .col-sm-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-sm-8:last-child {
      margin-right: 0; }
  .col-sm-push-8 {
    margin-left: 68.23843%; }
  .col-sm-pull-8 {
    margin-left: -68.23843%; }
  .col-sm-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-sm-9:last-child {
      margin-right: 0; }
  .col-sm-push-9 {
    margin-left: 76.76824%; }
  .col-sm-pull-9 {
    margin-left: -76.76824%; }
  .col-sm-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-sm-10:last-child {
      margin-right: 0; }
  .col-sm-push-10 {
    margin-left: 85.29804%; }
  .col-sm-pull-10 {
    margin-left: -85.29804%; }
  .col-sm-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-sm-11:last-child {
      margin-right: 0; }
  .col-sm-push-11 {
    margin-left: 93.82785%; }
  .col-sm-pull-11 {
    margin-left: -93.82785%; }
  .col-sm-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-sm-12:last-child {
      margin-right: 0; }
  .col-sm-push-12 {
    margin-left: 102.35765%; }
  .col-sm-pull-12 {
    margin-left: -102.35765%; }
  .sm--omega {
    margin-right: 0; }
  .sm--bottom-margin {
    margin-bottom: 2em; }
  .sm--top-margin {
    margin-top: 2em; }
  .sm--float-left {
    float: left; }
  .sm--float-right {
    float: right; }
  .sm--text-center {
    text-align: center; }
  .sm--text-left {
    text-align: left; }
  .sm--text-right {
    text-align: right; } }

@media (min-width: 48rem) {
  .col-md-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-md-1:last-child {
      margin-right: 0; }
  .col-md-push-1 {
    margin-left: 8.5298%; }
  .col-md-pull-1 {
    margin-left: -8.5298%; }
  .col-md-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-md-2:last-child {
      margin-right: 0; }
  .col-md-push-2 {
    margin-left: 17.05961%; }
  .col-md-pull-2 {
    margin-left: -17.05961%; }
  .col-md-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-md-3:last-child {
      margin-right: 0; }
  .col-md-push-3 {
    margin-left: 25.58941%; }
  .col-md-pull-3 {
    margin-left: -25.58941%; }
  .col-md-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-md-4:last-child {
      margin-right: 0; }
  .col-md-push-4 {
    margin-left: 34.11922%; }
  .col-md-pull-4 {
    margin-left: -34.11922%; }
  .col-md-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-md-5:last-child {
      margin-right: 0; }
  .col-md-push-5 {
    margin-left: 42.64902%; }
  .col-md-pull-5 {
    margin-left: -42.64902%; }
  .col-md-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-md-6:last-child {
      margin-right: 0; }
  .col-md-push-6 {
    margin-left: 51.17883%; }
  .col-md-pull-6 {
    margin-left: -51.17883%; }
  .col-md-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-md-7:last-child {
      margin-right: 0; }
  .col-md-push-7 {
    margin-left: 59.70863%; }
  .col-md-pull-7 {
    margin-left: -59.70863%; }
  .col-md-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-md-8:last-child {
      margin-right: 0; }
  .col-md-push-8 {
    margin-left: 68.23843%; }
  .col-md-pull-8 {
    margin-left: -68.23843%; }
  .col-md-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-md-9:last-child {
      margin-right: 0; }
  .col-md-push-9 {
    margin-left: 76.76824%; }
  .col-md-pull-9 {
    margin-left: -76.76824%; }
  .col-md-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-md-10:last-child {
      margin-right: 0; }
  .col-md-push-10 {
    margin-left: 85.29804%; }
  .col-md-pull-10 {
    margin-left: -85.29804%; }
  .col-md-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-md-11:last-child {
      margin-right: 0; }
  .col-md-push-11 {
    margin-left: 93.82785%; }
  .col-md-pull-11 {
    margin-left: -93.82785%; }
  .col-md-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-md-12:last-child {
      margin-right: 0; }
  .col-md-push-12 {
    margin-left: 102.35765%; }
  .col-md-pull-12 {
    margin-left: -102.35765%; }
  .md--omega {
    margin-right: 0; }
  .md--bottom-margin {
    margin-bottom: 2em; }
  .md--top-margin {
    margin-top: 2em; }
  .md--float-left {
    float: left; }
  .md--float-right {
    float: right; }
  .md--text-center {
    text-align: center; }
  .md--text-left {
    text-align: left; }
  .md--text-right {
    text-align: right; } }

@media (min-width: 62rem) {
  .col-lg-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-lg-1:last-child {
      margin-right: 0; }
  .col-lg-push-1 {
    margin-left: 8.5298%; }
  .col-lg-pull-1 {
    margin-left: -8.5298%; }
  .col-lg-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-lg-2:last-child {
      margin-right: 0; }
  .col-lg-push-2 {
    margin-left: 17.05961%; }
  .col-lg-pull-2 {
    margin-left: -17.05961%; }
  .col-lg-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-lg-3:last-child {
      margin-right: 0; }
  .col-lg-push-3 {
    margin-left: 25.58941%; }
  .col-lg-pull-3 {
    margin-left: -25.58941%; }
  .col-lg-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-lg-4:last-child {
      margin-right: 0; }
  .col-lg-push-4 {
    margin-left: 34.11922%; }
  .col-lg-pull-4 {
    margin-left: -34.11922%; }
  .col-lg-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-lg-5:last-child {
      margin-right: 0; }
  .col-lg-push-5 {
    margin-left: 42.64902%; }
  .col-lg-pull-5 {
    margin-left: -42.64902%; }
  .col-lg-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-lg-6:last-child {
      margin-right: 0; }
  .col-lg-push-6 {
    margin-left: 51.17883%; }
  .col-lg-pull-6 {
    margin-left: -51.17883%; }
  .col-lg-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-lg-7:last-child {
      margin-right: 0; }
  .col-lg-push-7 {
    margin-left: 59.70863%; }
  .col-lg-pull-7 {
    margin-left: -59.70863%; }
  .col-lg-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-lg-8:last-child {
      margin-right: 0; }
  .col-lg-push-8 {
    margin-left: 68.23843%; }
  .col-lg-pull-8 {
    margin-left: -68.23843%; }
  .col-lg-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-lg-9:last-child {
      margin-right: 0; }
  .col-lg-push-9 {
    margin-left: 76.76824%; }
  .col-lg-pull-9 {
    margin-left: -76.76824%; }
  .col-lg-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-lg-10:last-child {
      margin-right: 0; }
  .col-lg-push-10 {
    margin-left: 85.29804%; }
  .col-lg-pull-10 {
    margin-left: -85.29804%; }
  .col-lg-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-lg-11:last-child {
      margin-right: 0; }
  .col-lg-push-11 {
    margin-left: 93.82785%; }
  .col-lg-pull-11 {
    margin-left: -93.82785%; }
  .col-lg-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-lg-12:last-child {
      margin-right: 0; }
  .col-lg-push-12 {
    margin-left: 102.35765%; }
  .col-lg-pull-12 {
    margin-left: -102.35765%; }
  .lg--omega {
    margin-right: 0; }
  .lg--bottom-margin {
    margin-bottom: 2em; }
  .lg--top-margin {
    margin-top: 2em; }
  .lg--float-left {
    float: left; }
  .lg--float-right {
    float: right; }
  .lg--text-center {
    text-align: center; }
  .lg--text-left {
    text-align: left; }
  .lg--text-right {
    text-align: right; } }

@media (min-width: 75rem) {
  .col-xl-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-xl-1:last-child {
      margin-right: 0; }
  .col-xl-push-1 {
    margin-left: 8.5298%; }
  .col-xl-pull-1 {
    margin-left: -8.5298%; }
  .col-xl-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-xl-2:last-child {
      margin-right: 0; }
  .col-xl-push-2 {
    margin-left: 17.05961%; }
  .col-xl-pull-2 {
    margin-left: -17.05961%; }
  .col-xl-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-xl-3:last-child {
      margin-right: 0; }
  .col-xl-push-3 {
    margin-left: 25.58941%; }
  .col-xl-pull-3 {
    margin-left: -25.58941%; }
  .col-xl-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-xl-4:last-child {
      margin-right: 0; }
  .col-xl-push-4 {
    margin-left: 34.11922%; }
  .col-xl-pull-4 {
    margin-left: -34.11922%; }
  .col-xl-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-xl-5:last-child {
      margin-right: 0; }
  .col-xl-push-5 {
    margin-left: 42.64902%; }
  .col-xl-pull-5 {
    margin-left: -42.64902%; }
  .col-xl-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-xl-6:last-child {
      margin-right: 0; }
  .col-xl-push-6 {
    margin-left: 51.17883%; }
  .col-xl-pull-6 {
    margin-left: -51.17883%; }
  .col-xl-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-xl-7:last-child {
      margin-right: 0; }
  .col-xl-push-7 {
    margin-left: 59.70863%; }
  .col-xl-pull-7 {
    margin-left: -59.70863%; }
  .col-xl-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-xl-8:last-child {
      margin-right: 0; }
  .col-xl-push-8 {
    margin-left: 68.23843%; }
  .col-xl-pull-8 {
    margin-left: -68.23843%; }
  .col-xl-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-xl-9:last-child {
      margin-right: 0; }
  .col-xl-push-9 {
    margin-left: 76.76824%; }
  .col-xl-pull-9 {
    margin-left: -76.76824%; }
  .col-xl-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-xl-10:last-child {
      margin-right: 0; }
  .col-xl-push-10 {
    margin-left: 85.29804%; }
  .col-xl-pull-10 {
    margin-left: -85.29804%; }
  .col-xl-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-xl-11:last-child {
      margin-right: 0; }
  .col-xl-push-11 {
    margin-left: 93.82785%; }
  .col-xl-pull-11 {
    margin-left: -93.82785%; }
  .col-xl-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-xl-12:last-child {
      margin-right: 0; }
  .col-xl-push-12 {
    margin-left: 102.35765%; }
  .col-xl-pull-12 {
    margin-left: -102.35765%; }
  .xl--omega {
    margin-right: 0; }
  .xl--bottom-margin {
    margin-bottom: 2em; }
  .xl--top-margin {
    margin-top: 2em; }
  .xl--float-left {
    float: left; }
  .xl--float-right {
    float: right; }
  .xl--text-center {
    text-align: center; }
  .xl--text-left {
    text-align: left; }
  .xl--text-right {
    text-align: right; } }

@media (min-width: 87.5rem) {
  .col-xxl-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
    padding: 0; }
    .col-xxl-1:last-child {
      margin-right: 0; }
  .col-xxl-push-1 {
    margin-left: 8.5298%; }
  .col-xxl-pull-1 {
    margin-left: -8.5298%; }
  .col-xxl-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    padding: 0; }
    .col-xxl-2:last-child {
      margin-right: 0; }
  .col-xxl-push-2 {
    margin-left: 17.05961%; }
  .col-xxl-pull-2 {
    margin-left: -17.05961%; }
  .col-xxl-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    padding: 0; }
    .col-xxl-3:last-child {
      margin-right: 0; }
  .col-xxl-push-3 {
    margin-left: 25.58941%; }
  .col-xxl-pull-3 {
    margin-left: -25.58941%; }
  .col-xxl-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    padding: 0; }
    .col-xxl-4:last-child {
      margin-right: 0; }
  .col-xxl-push-4 {
    margin-left: 34.11922%; }
  .col-xxl-pull-4 {
    margin-left: -34.11922%; }
  .col-xxl-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    padding: 0; }
    .col-xxl-5:last-child {
      margin-right: 0; }
  .col-xxl-push-5 {
    margin-left: 42.64902%; }
  .col-xxl-pull-5 {
    margin-left: -42.64902%; }
  .col-xxl-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding: 0; }
    .col-xxl-6:last-child {
      margin-right: 0; }
  .col-xxl-push-6 {
    margin-left: 51.17883%; }
  .col-xxl-pull-6 {
    margin-left: -51.17883%; }
  .col-xxl-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
    padding: 0; }
    .col-xxl-7:last-child {
      margin-right: 0; }
  .col-xxl-push-7 {
    margin-left: 59.70863%; }
  .col-xxl-pull-7 {
    margin-left: -59.70863%; }
  .col-xxl-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 0; }
    .col-xxl-8:last-child {
      margin-right: 0; }
  .col-xxl-push-8 {
    margin-left: 68.23843%; }
  .col-xxl-pull-8 {
    margin-left: -68.23843%; }
  .col-xxl-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
    padding: 0; }
    .col-xxl-9:last-child {
      margin-right: 0; }
  .col-xxl-push-9 {
    margin-left: 76.76824%; }
  .col-xxl-pull-9 {
    margin-left: -76.76824%; }
  .col-xxl-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    padding: 0; }
    .col-xxl-10:last-child {
      margin-right: 0; }
  .col-xxl-push-10 {
    margin-left: 85.29804%; }
  .col-xxl-pull-10 {
    margin-left: -85.29804%; }
  .col-xxl-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
    padding: 0; }
    .col-xxl-11:last-child {
      margin-right: 0; }
  .col-xxl-push-11 {
    margin-left: 93.82785%; }
  .col-xxl-pull-11 {
    margin-left: -93.82785%; }
  .col-xxl-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    padding: 0; }
    .col-xxl-12:last-child {
      margin-right: 0; }
  .col-xxl-push-12 {
    margin-left: 102.35765%; }
  .col-xxl-pull-12 {
    margin-left: -102.35765%; }
  .xxl--omega {
    margin-right: 0; }
  .xxl--bottom-margin {
    margin-bottom: 2em; }
  .xxl--top-margin {
    margin-top: 2em; }
  .xxl--float-left {
    float: left; }
  .xxl--float-right {
    float: right; }
  .xxl--text-center {
    text-align: center; }
  .xxl--text-left {
    text-align: left; }
  .xxl--text-right {
    text-align: right; } }

.wasteking.overflow-hidden {
  margin-top: 0; }

.wasteking .bleed:before, .wasteking .bleed:after {
  height: 95.5%; }

.wasteking .banner.banner--homepage .banner__image-bg {
  min-height: 425px;
  width: 100%;
  max-width: unset; }
  @media screen and (min-width: 48rem) {
    .wasteking .banner.banner--homepage .banner__image-bg {
      height: 100%; } }

.wasteking .banner.banner--homepage .banner__inner {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 425px;
  background-color: transparent; }

.wasteking .banner.banner--homepage .banner__inner-left .text-box {
  background: transparent;
  padding: 50px 40px; }
  @media screen and (min-width: 48rem) {
    .wasteking .banner.banner--homepage .banner__inner-left .text-box {
      padding: 0px 30px 50px 70px; } }
  @media screen and (min-width: 62rem) {
    .wasteking .banner.banner--homepage .banner__inner-left .text-box {
      padding: 0px 30px 50px 100px; } }
  @media screen and (min-width: 75rem) {
    .wasteking .banner.banner--homepage .banner__inner-left .text-box {
      padding: 0px 30px 50px 130px; } }
  .wasteking .banner.banner--homepage .banner__inner-left .text-box .text-box__inner {
    padding: 1em;
    background-color: rgba(139, 141, 141, 0.5); }

.wasteking .homepage__banner--video .banner .banner__inner .text-box .text-box__subtitle {
  color: #fff !important; }
  @media (min-width: 370px) {
    .wasteking .homepage__banner--video .banner .banner__inner .text-box .text-box__subtitle {
      color: #fff !important; } }

.wasteking .homepage__banner--video .banner .banner__inner .text-box .text-box__body {
  color: #fff !important; }
  @media (min-width: 370px) {
    .wasteking .homepage__banner--video .banner .banner__inner .text-box .text-box__body {
      color: #fff !important; } }

.wasteking .text-center {
  padding: 0 20px;
  max-width: 900px;
  margin: 0 auto 45px; }
  .wasteking .text-center .card-list__heading {
    text-transform: uppercase;
    color: #000;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 1.25em;
    color: #2A2A2C; }
    @media screen and (min-width: 48rem) {
      .wasteking .text-center .card-list__heading {
        font-size: 1.75em; } }
  .wasteking .text-center p {
    color: #56565C;
    font-size: 1em; }
    @media screen and (min-width: 48rem) {
      .wasteking .text-center p {
        font-size: 1.25em; } }

.wasteking .card-list-animated {
  max-width: 1040px;
  width: 100%; }
  @media screen and (min-width: 48rem) {
    .wasteking .card-list-animated {
      width: 100%; } }
  .wasteking .card-list-animated .card-animated {
    min-height: 136px;
    margin: 15px auto;
    background-color: #F1F2F4;
    max-width: 338px;
    max-height: 450px; }
    @media screen and (min-width: 48rem) {
      .wasteking .card-list-animated .card-animated {
        min-height: 440px;
        margin: 10px 9px; }
        .wasteking .card-list-animated .card-animated:first-child {
          margin-left: 0; }
        .wasteking .card-list-animated .card-animated:last-child {
          margin-right: 0; } }
    .wasteking .card-list-animated .card-animated:before {
      background: none; }
    .wasteking .card-list-animated .card-animated__inner {
      background-color: #F1F2F4;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      height: 136px; }
      @media screen and (min-width: 48rem) {
        .wasteking .card-list-animated .card-animated__inner {
          height: 440px;
          align-items: center; } }
    .wasteking .card-list-animated .card-animated__image {
      max-height: 136px;
      height: 100%;
      float: right;
      margin-right: 20px; }
      @media screen and (min-width: 48rem) {
        .wasteking .card-list-animated .card-animated__image {
          max-height: 300px;
          float: none;
          margin-right: 0; } }
    .wasteking .card-list-animated .card-animated__captions {
      bottom: 0;
      top: calc(50% - 50px);
      text-align: left; }
      @media screen and (min-width: 48rem) {
        .wasteking .card-list-animated .card-animated__captions {
          text-align: center;
          top: 40px; } }
    .wasteking .card-list-animated .card-animated__caption--1 {
      font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      padding-bottom: 0.9375em;
      color: #2A2A2C;
      font-size: 2em;
      padding-bottom: 18px; }
      @media screen and (min-width: 48rem) {
        .wasteking .card-list-animated .card-animated__caption--1 {
          font-size: 2.5em;
          padding-bottom: 12px; } }
    .wasteking .card-list-animated .card-animated__caption--2 {
      font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      color: #2A2A2C;
      font-size: 1.125em;
      padding-bottom: 0.75em; }
      @media screen and (min-width: 48rem) {
        .wasteking .card-list-animated .card-animated__caption--2 {
          text-align: center;
          font-size: 1.25em;
          padding-bottom: 0; } }
    .wasteking .card-list-animated .card-animated__caption--3 {
      font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      color: #802428;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.8125em; }
      @media screen and (min-width: 48rem) {
        .wasteking .card-list-animated .card-animated__caption--3 {
          text-align: center; } }

.wasteking .content-container__content {
  text-align: left; }
  .wasteking .content-container__content h2 {
    margin-bottom: 1.5em;
    text-align: center; }

#wk-search .search-results-page-header {
  margin: 3em 0; }
  #wk-search .search-results-page-header h1 {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

#wk-search .search-results-navigation__item .button {
  background-color: #802428;
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.tile-row-button {
  width: 100%; }

.search-results.wasteking .search-results-section-header {
  border-bottom: 3px solid #802428; }
  .search-results.wasteking .search-results-section-header__link {
    color: #802428; }

.header-main {
  border-bottom: none; }

.header-logo {
  margin: 0 0 0 20px; }
  .header-logo__image {
    height: 20px;
    width: 166px; }

.header-container {
  flex-direction: row;
  flex-wrap: wrap; }
  @media screen and (min-width: 48rem) {
    .header-container {
      flex-wrap: nowrap; } }

.site-header-search {
  order: 1;
  width: 100%;
  display: block;
  border-top: 1px solid #D1D1D1; }
  .site-header-search.hide {
    display: none; }
  @media screen and (min-width: 28rem) {
    .site-header-search {
      margin: 0 15px 0 0; } }
  @media screen and (min-width: 34rem) {
    .site-header-search {
      width: 55%;
      max-width: 325px;
      margin: 19px 15px 18px 0;
      order: 0;
      border-top: none; } }
  .site-header-search .container {
    margin: 0;
    min-width: 100%; }
    .site-header-search .container .search-results-page-header {
      margin-bottom: 0; }
      .site-header-search .container .search-results-page-header .input-add-on {
        width: 100% !important;
        flex: 1 0 40% !important; }
        .site-header-search .container .search-results-page-header .input-add-on .input-add-on__field {
          border-right: none; }
        .site-header-search .container .search-results-page-header .input-add-on .input-add-on__item {
          border-left: none; }
  .site-header-search__icon {
    border: none;
    height: 30px;
    width: 30px;
    order: 0;
    margin-right: 10px;
    display: block; }
    .site-header-search__icon.hide {
      display: none; }
    @media screen and (min-width: 34rem) {
      .site-header-search__icon {
        margin: 18px 25px 17px 0; } }

.header-search-container {
  display: none; }
  .site-header-search--mobile .header-search-container {
    background: white;
    box-shadow: none;
    display: block;
    padding: 0 10px;
    position: initial;
    width: 100%; }

.search-box {
  border: none;
  height: 40px; }
  @media screen and (min-width: 34rem) {
    .search-box {
      border: 1px solid #D1D1D1;
      height: 35px; } }
  @media screen and (min-width: 48rem) {
    .search-box {
      border: 1px solid #D1D1D1; } }
  .search-box__input {
    align-self: stretch;
    background: transparent;
    color: #56565C;
    flex: 1 1 auto;
    font-family: "DINWebRegular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 14px;
    padding: 0 15px;
    font-size: 14px !important; }
    .search-box__input#search_terms {
      font-size: 14px !important; }
    @media screen and (min-width: 28rem) {
      .search-box__input {
        max-width: none; } }
    @media screen and (min-width: 62rem) {
      .search-box__input {
        max-width: none;
        padding: 0 13px;
        font-size: 14px !important; } }
  .search-box__button {
    background-image: url("images/magnifying-glass-grey_thin.svg"); }
    @media screen and (min-width: 34rem) {
      .search-box__button {
        background-image: url("images/magnifying-glass-red_thin.svg"); } }

.secondary-menu-toggle {
  display: flex; }
  @media screen and (min-width: 48rem) {
    .secondary-menu-toggle {
      display: none; } }

.navicon {
  background-color: #fff; }
  .navicon__icon {
    background-color: #802428; }
    .navicon__icon::before, .navicon__icon::after {
      background-color: #802428; }

.secondary-menu {
  padding: 0 20px;
  box-shadow: 0 6px 16px -7px rgba(114, 115, 116, 0.5); }
  @media screen and (min-width: 48rem) {
    .secondary-menu {
      max-width: 300px;
      padding: 30px 40px; } }
  .secondary-menu__buy-moen {
    display: none; }

.featured-inspiration {
  display: none; }

.tools-nav {
  display: none; }

.mobile-nav {
  margin-bottom: 0;
  border-top: 1px solid #D1D1D1; }
  .mobile-nav__link {
    border-top: none !important;
    border-bottom: 1px solid #D1D1D1;
    text-transform: uppercase;
    font-size: 0.9375em;
    font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-weight: bold; }
    .mobile-nav__link::after {
      height: 13px;
      width: 13px;
      background-image: url("./images/icon-right-open_red.svg");
      background-repeat: no-repeat; }
    .mobile-nav__link:hover {
      background: #fff;
      color: inherit; }
    .mobile-nav__link--active {
      background: #fff;
      color: inherit; }
      .mobile-nav__link--active::after {
        transform: rotate(180deg); }
    .mobile-nav__link--additional {
      display: none; }

.mobile-sub-nav__list {
  font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  text-transform: uppercase;
  font-size: 0.8125em;
  margin-bottom: 0; }

.mobile-sub-nav__link {
  padding: 30px 50px 30px 20px;
  border-bottom: 1px solid #D1D1D1;
  background-color: #F1F2F4; }
  .mobile-sub-nav__link::after {
    content: '';
    height: 13px;
    width: 13px;
    background-image: url("./images/icon-right-open_red.svg");
    background-repeat: no-repeat;
    position: absolute;
    right: 27px; }
  @media screen and (min-width: 48rem) {
    .mobile-sub-nav__link {
      padding: 12px 20px; } }

.mobile-sub-nav__image {
  display: none; }

.mobile-sub-nav__label {
  position: relative;
  left: 55px; }

.mobile-sub-nav__item {
  border-bottom: none; }

.mobile-sub-nav__view-all {
  display: none; }

.main-nav__link {
  color: #56565C;
  text-transform: uppercase;
  font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 0.875em;
  letter-spacing: 0.1em; }
  .main-nav__link.focus, .main-nav__link.hover, .main-nav__link.open {
    color: #802428; }
    .main-nav__link.focus:after, .main-nav__link.hover:after, .main-nav__link.open:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #F1F2F4;
      position: absolute;
      bottom: -1px;
      left: calc(50% - 15px);
      z-index: 100; }

.main-nav__flyout {
  background-color: #F1F2F4;
  box-shadow: 0 4px 6px -5px #8b8d8e;
  top: 100%; }

.main-sub-nav__list {
  justify-content: flex-start; }

.main-sub-nav__item {
  margin: 15px 30px; }

.main-sub-nav__link {
  text-align: left;
  margin: 15px 0;
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  width: fit-content; }
  .main-sub-nav__link .link-image-menu__label {
    text-transform: capitalize; }
    .main-sub-nav__link .link-image-menu__label:after {
      content: "";
      width: 13px;
      height: 13px;
      background: url("./images/icon-right-open_red.svg") no-repeat center;
      top: 2px;
      right: -20px;
      position: absolute;
      display: none; }
    .main-sub-nav__link .link-image-menu__label:hover, .main-sub-nav__link .link-image-menu__label:focus {
      color: #802428;
      position: relative; }
      .main-sub-nav__link .link-image-menu__label:hover:after, .main-sub-nav__link .link-image-menu__label:focus:after {
        display: inline-block; }
  .main-sub-nav__link .submenu-header {
    font-weight: bold;
    text-transform: uppercase; }
    .main-sub-nav__link .submenu-header:after {
      top: -1px; }

.global-footer-container {
  flex-direction: column;
  align-content: center;
  align-items: center; }
  @media screen and (min-width: 34rem) {
    .global-footer-container {
      flex-direction: row;
      align-content: flex-start;
      align-items: flex-start; } }
  .global-footer-container__col--last {
    display: none; }

.global-footer {
  border-top: 1px solid #D1D1D1; }
  @media screen and (min-width: 48rem) {
    .global-footer {
      margin-bottom: 0; } }
  .global-footer__logo {
    margin-bottom: 10px; }
    @media screen and (min-width: 48rem) {
      .global-footer__logo {
        margin-bottom: 0; } }
    .global-footer__logo__image {
      height: 65px;
      width: 111px; }
      @media screen and (min-width: 48rem) {
        .global-footer__logo__image {
          width: 180px;
          height: 40px; } }
      .global-footer__logo__image--horizontal {
        display: none; }
        @media screen and (min-width: 48rem) {
          .global-footer__logo__image--horizontal {
            display: block; } }
      .global-footer__logo__image--stacked {
        display: block;
        position: relative;
        left: 4px;
        margin: 0 auto; }
        @media screen and (min-width: 48rem) {
          .global-footer__logo__image--stacked {
            display: none; } }
  .global-footer__copyright {
    text-align: center; }
    @media screen and (min-width: 48rem) {
      .global-footer__copyright {
        margin-left: -7px; } }

.footer-nav {
  flex-direction: column;
  margin-bottom: 0; }
  @media screen and (min-width: 34rem) {
    .footer-nav {
      flex-direction: row;
      justify-content: flex-end !important; } }
  .footer-nav__list {
    text-align: center;
    margin: 0 0 1.875rem 0;
    padding-bottom: 10px; }
    @media screen and (min-width: 34rem) {
      .footer-nav__list {
        text-align: right;
        margin: 0 0 0 3.75rem; } }
    .footer-nav__list:first-child {
      margin-right: 0; }
    .footer-nav__list:last-child {
      margin-bottom: 0; }
  .footer-nav__item {
    color: #56565C;
    font-size: 16px;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    padding-bottom: 7px; }
    .footer-nav__item:first-child {
      padding-bottom: 12px; }
    @media screen and (min-width: 48rem) {
      .footer-nav__item {
        padding-bottom: 0; } }
    .footer-nav__item a {
      color: #56565C; }
      .footer-nav__item a.footer-header {
        color: #2A2A2C;
        font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        font-size: 13px;
        text-transform: uppercase; }
    .footer-nav__item .footer-header {
      color: #2A2A2C;
      font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      font-size: 13px;
      text-transform: uppercase; }

.wasteking .banner__image-bg {
  width: 100% !important;
  height: 100% !important;
  max-width: initial !important; }
  @media screen and (min-width: 48rem) {
    .wasteking .banner__image-bg {
      width: 100%;
      height: 100%; } }
  @media screen and (min-width: 62rem) {
    .wasteking .banner__image-bg {
      width: 100%;
      height: 100%; } }

.wasteking .banner .text-box__subtitle {
  font-size: 1.625em !important; }
  @media screen and (min-width: 48rem) {
    .wasteking .banner .text-box__subtitle {
      font-size: 3em !important; } }

.wasteking .banner .text-box__body {
  font-size: 1.125em; }
  @media screen and (min-width: 48rem) {
    .wasteking .banner .text-box__body {
      font-size: 1.25em; } }

.wasteking .banner .banner-text-brandon__bold {
  font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  text-transform: uppercase; }

.wasteking .banner .banner-text-brandon__light {
  font-family: "Brandon-light", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.wasteking#about-us {
  height: auto; }
  .wasteking#about-us .bleed:before, .wasteking#about-us .bleed:after {
    height: 93.1%; }
  .wasteking#about-us .bleed::before {
    transform: translateX(-99%); }
  .wasteking#about-us .banner {
    height: 290px; }
    @media screen and (min-width: 48rem) {
      .wasteking#about-us .banner {
        height: 400px; } }
    @media screen and (min-width: 62rem) {
      .wasteking#about-us .banner {
        height: 489px; } }

.banner-hero.banner-hero--cta-left .banner-hero__content {
  padding: 30px; }

.card-list {
  max-width: none; }
  .card-list--simple-white-boxes {
    background-color: #F8F8F9;
    padding: 45px 22px 60px; }
    @media screen and (min-width: 48rem) {
      .card-list--simple-white-boxes {
        padding: 55px 20px 95px; } }
    .card-list--simple-white-boxes .card-list__title {
      font-size: 26px;
      margin-top: 0px; }
      @media screen and (min-width: 48rem) {
        .card-list--simple-white-boxes .card-list__title {
          font-size: 42px; } }
    .card-list--simple-white-boxes .card-list__heading {
      margin-bottom: 0; }
      .card-list--simple-white-boxes .card-list__heading p {
        font-size: 16px;
        padding-bottom: 0;
        letter-spacing: 0px; }
        @media screen and (min-width: 48rem) {
          .card-list--simple-white-boxes .card-list__heading p {
            font-size: 20px; } }
    .card-list--simple-white-boxes .card-list__list {
      padding-top: 30px; }
      @media screen and (min-width: 48rem) {
        .card-list--simple-white-boxes .card-list__list {
          padding-top: 60px; } }
      .card-list--simple-white-boxes .card-list__list .card {
        min-width: 328px;
        min-height: 300px;
        max-width: 380px;
        max-height: 306px; }
        @media screen and (min-width: 48rem) {
          .card-list--simple-white-boxes .card-list__list .card {
            margin: 0 10px; }
            .card-list--simple-white-boxes .card-list__list .card:first-child {
              margin-left: 0; }
            .card-list--simple-white-boxes .card-list__list .cardlast-child {
              margin-right: 0; } }
        .card-list--simple-white-boxes .card-list__list .card--simple-white-box {
          box-shadow: none;
          border: 1px solid #DBDBDB;
          min-height: 300px;
          padding: 50px 30px 40px;
          margin-bottom: 30px; }
          @media screen and (min-width: 48rem) {
            .card-list--simple-white-boxes .card-list__list .card--simple-white-box {
              padding: 55px 40px 45px;
              margin-bottom: 20px; } }
        .card-list--simple-white-boxes .card-list__list .card__image {
          width: 100px; }
        .card-list--simple-white-boxes .card-list__list .card__title {
          font-size: 15px;
          text-transform: uppercase;
          color: #427082;
          font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
          letter-spacing: 1px; }
        .card-list--simple-white-boxes .card-list__list .card__subtitle p {
          font-size: 16px;
          padding-bottom: 0; }
          @media screen and (min-width: 48rem) {
            .card-list--simple-white-boxes .card-list__list .card__subtitle p {
              font-size: 20px; } }
  .card-list__title {
    font-size: 1.75em; }
  .card-list__heading {
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !important; }
  .card-list .card.card--simple-white-box {
    justify-content: space-between; }

.card--top-blue-border {
  border-top: 4px solid #802428;
  height: 340px;
  min-height: 340px;
  padding: 0;
  margin-bottom: 1.25em;
  transition: height .3s,box-shadow .3s; }

.card--top-blue-border .card__title {
  color: #802428;
  font-size: 1.625em;
  font-family: DINWebLight,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
  font-weight: 400;
  margin: 0 0 .625em;
  text-align: center; }

.link {
  color: #802428; }

.card--top-blue-border .pointer-item.is-selected:after {
  border-top-color: #802428; }

.card-list--text-left-2-tall-right .card-list__content {
  border-top: 4px solid #802428; }

.card-list--text-left-2-tall-right .card__back-content {
  background-color: #802428; }

.product-gallery .facets__main {
  padding: 1.25em 1.5em;
  background-color: #802428;
  justify-content: center; }

.product-gallery .facets__button-filters {
  background-color: #802428;
  padding: 16px 1em; }

.product-gallery .facets__filters.is-active {
  position: relative; }
  @media screen and (min-width: 34rem) {
    .product-gallery .facets__filters.is-active {
      right: calc(50% - 218px); } }
  @media screen and (min-width: 62rem) {
    .product-gallery .facets__filters.is-active {
      right: 0; } }

.product-gallery .facets__inner {
  background-color: #F1F2F4; }

.product-gallery .facets__button {
  background-color: #D1D1D1;
  color: #fff;
  border: none;
  font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  word-spacing: 2px; }
  @media screen and (min-width: 62rem) {
    .product-gallery .facets__button {
      background-color: transparent;
      border-bottom: 5px solid #D1D1D1;
      color: #78777B;
      font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      text-transform: capitalize;
      font-size: 18px;
      word-spacing: 2px;
      min-width: 200px;
      text-align: center; }
      .product-gallery .facets__button:hover {
        color: inherit; } }
  .product-gallery .facets__button.is-active {
    background-color: #802428;
    border: none; }
    @media screen and (min-width: 62rem) {
      .product-gallery .facets__button.is-active {
        background-color: transparent;
        border-bottom: 5px solid #427082;
        color: #427082; }
        .product-gallery .facets__button.is-active:hover {
          background-color: #e2e2e2; } }
  .product-gallery .facets__button:after {
    border-top-color: #fff; }

@media screen and (min-width: 34rem) {
  .product-gallery .facets__main-buttons {
    flex-basis: auto; } }

.product-gallery .facets__clear-all {
  background-color: transparent;
  text-transform: capitalize;
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  border: none;
  border-bottom: none !important;
  color: white !important; }

.product-gallery .facets__button-apply-filter {
  background-color: #427082; }
  @media screen and (min-width: 34rem) {
    .product-gallery .facets__button-apply-filter {
      padding: 16px 1em; } }
  @media screen and (min-width: 62rem) {
    .product-gallery .facets__button-apply-filter {
      background-color: #802428;
      font-size: 13px;
      text-transform: uppercase;
      font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      padding: 13px 25px;
      margin-bottom: 13px; } }

.product-gallery .facets__ck {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }
  @media screen and (min-width: 62rem) {
    .product-gallery .facets__ck.form-checkbox--white {
      font-size: 18px; } }
  .product-gallery .facets__ck.form-checkbox--white [type="checkbox"]:not(:checked) + label:before, .product-gallery .facets__ck.form-checkbox--white [type="checkbox"]:checked + label:before {
    background-color: #fff;
    height: 22px;
    top: -2px;
    left: -4px;
    width: 22px; }
  .product-gallery .facets__ck.form-checkbox--white [type="checkbox"]:checked + label:after {
    content: '';
    height: 17px;
    top: 0px;
    left: -2px;
    width: 17px;
    background: url(./images/icon-checkmark_teal.svg) no-repeat; }

.product-gallery .results {
  max-width: 1200px; }
  .product-gallery .results__header {
    margin: 0 25px 2em; }
    @media screen and (min-width: 48rem) {
      .product-gallery .results__header {
        margin: 0 0 2em; } }

.product-tile {
  width: 50%;
  max-width: 159px;
  border: 1px solid transparent; }
  @media screen and (min-width: 48rem) {
    .product-tile {
      width: 25%;
      max-width: 250px;
      margin: 1px; } }
  @media screen and (min-width: 75rem) {
    .product-tile {
      max-width: 298px; } }
  @media screen and (min-width: 87.5rem) {
    .product-tile {
      max-width: 298px; } }
  .product-tile__head .product-tile__like {
    display: none; }
  .product-tile__name {
    max-width: 325px;
    font-size: 20px;
    color: #427082;
    line-height: 1em; }
    @media screen and (min-width: 48rem) {
      .product-tile__name {
        font-size: 24px; } }
  .product-tile__image {
    max-height: 165px;
    width: auto;
    max-width: unset; }
  .product-tile__msrp {
    display: inline-flex; }
  .product-tile__sku {
    text-transform: uppercase;
    font-size: 13px;
    font-family: "Brandon-medium", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    color: #56565C; }

.tile:hover, .tile:focus {
  background-color: white;
  box-shadow: 0px 0px 6px 0px #EDEDED;
  border: 1px solid #DBDBDB; }

@media screen and (min-width: 62rem) {
  .tile-row {
    justify-content: center; } }

.banner-article {
  margin: 0 auto;
  max-width: unset; }
  .banner-article__inner {
    max-width: 1300px;
    width: 100%;
    display: flex;
    margin: 0 auto; }
  .banner-article__image-container {
    display: none; }
    @media screen and (min-width: 48rem) {
      .banner-article__image-container {
        display: block; } }
  .banner-article--content-right .banner-article__inner {
    flex-direction: row; }
    .banner-article--content-right .banner-article__inner .banner-article__content {
      padding: 25px; }
      @media screen and (min-width: 48rem) {
        .banner-article--content-right .banner-article__inner .banner-article__content {
          padding: 75px 60px; } }
      @media screen and (min-width: 75rem) {
        .banner-article--content-right .banner-article__inner .banner-article__content {
          padding: 75px 0px 75px 60px; } }
  .banner-article--content-left .banner-article__inner {
    flex-direction: row-reverse; }
    .banner-article--content-left .banner-article__inner .banner-article__content {
      padding: 25px; }
      @media screen and (min-width: 48rem) {
        .banner-article--content-left .banner-article__inner .banner-article__content {
          padding: 75px 60px; } }
      @media screen and (min-width: 75rem) {
        .banner-article--content-left .banner-article__inner .banner-article__content {
          padding: 75px 60px 75px 0px; } }
  .banner-article__content {
    text-align: left; }
  .banner-article__title {
    font-size: 1.125em;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    text-transform: uppercase; }
    @media screen and (min-width: 48rem) {
      .banner-article__title {
        font-size: 1.5em; } }
  .banner-article__title-link {
    color: #000; }
    .banner-article__title-link:hover {
      text-decoration: none; }
  .banner-article__subtitle {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }
  .banner-article__description {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }
  .banner-article__link {
    font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    color: #802428;
    font-size: 0.8125em;
    text-transform: uppercase; }
    .banner-article__link:hover {
      text-decoration: none; }

#about-us-even .banner-article__content,
#about-us-odd .banner-article__content {
  padding: 50px 25px; }

#about-us-even .banner-article__title,
#about-us-odd .banner-article__title {
  font-size: 2em;
  text-transform: capitalize;
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  margin-bottom: 25px; }
  @media screen and (min-width: 48rem) {
    #about-us-even .banner-article__title,
    #about-us-odd .banner-article__title {
      font-size: 2.625em; } }

#about-us-even .banner-article__title-link,
#about-us-odd .banner-article__title-link {
  color: #56565C; }

#about-us-even .banner-article__description p,
#about-us-odd .banner-article__description p {
  color: #56565C;
  font-size: 16px;
  margin-bottom: 25px;
  padding-bottom: 0; }
  @media screen and (min-width: 48rem) {
    #about-us-even .banner-article__description p,
    #about-us-odd .banner-article__description p {
      font-size: 20px; } }

#about-us-odd {
  background-color: #F1F2F4; }

.banner-article__description, .banner-article__subtitle {
  font-family: Brandon-regular,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
  font-size: 1.25em; }

.banner-hero.wasteking#contact-us {
  margin: 50px 0; }
  @media screen and (min-width: 48rem) {
    .banner-hero.wasteking#contact-us {
      margin: 100px 0; } }
  .banner-hero.wasteking#contact-us .banner-hero__title {
    font-size: 26px;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    text-transform: uppercase;
    color: #2A2A2C;
    margin-bottom: 10px;
    padding-bottom: 0;
    letter-spacing: 1px; }
    @media screen and (min-width: 48rem) {
      .banner-hero.wasteking#contact-us .banner-hero__title {
        font-size: 48px;
        margin-bottom: 20px;
        padding-bottom: 0; } }
  .banner-hero.wasteking#contact-us .banner-hero__description p {
    font-size: 16px;
    padding-bottom: 5px; }
    @media screen and (min-width: 48rem) {
      .banner-hero.wasteking#contact-us .banner-hero__description p {
        font-size: 20px; } }
    .banner-hero.wasteking#contact-us .banner-hero__description p a {
      text-decoration: none;
      color: #427082; }

.banner-hero.wasteking#videos, .banner-hero.wasteking#accessories {
  margin: 0; }
  .banner-hero.wasteking#videos .banner-hero__image-container, .banner-hero.wasteking#accessories .banner-hero__image-container {
    overflow: hidden;
    height: 160px; }
    @media screen and (min-width: 48rem) {
      .banner-hero.wasteking#videos .banner-hero__image-container, .banner-hero.wasteking#accessories .banner-hero__image-container {
        height: 325px; } }
    @media screen and (min-width: 62rem) {
      .banner-hero.wasteking#videos .banner-hero__image-container, .banner-hero.wasteking#accessories .banner-hero__image-container {
        height: 490px; } }
  .banner-hero.wasteking#videos .banner-hero__title, .banner-hero.wasteking#accessories .banner-hero__title {
    font-size: 20px;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    text-transform: uppercase;
    color: #2A2A2C; }
    @media screen and (min-width: 48rem) {
      .banner-hero.wasteking#videos .banner-hero__title, .banner-hero.wasteking#accessories .banner-hero__title {
        font-size: 28px; } }
  .banner-hero.wasteking#videos .banner-hero__description p, .banner-hero.wasteking#accessories .banner-hero__description p {
    font-size: 16px; }
    @media screen and (min-width: 48rem) {
      .banner-hero.wasteking#videos .banner-hero__description p, .banner-hero.wasteking#accessories .banner-hero__description p {
        font-size: 20px; } }

.banner-hero.wasteking#accessories .banner-hero__content {
  margin: 40px auto 20px; }
  @media screen and (min-width: 48rem) {
    .banner-hero.wasteking#accessories .banner-hero__content {
      margin: 75px auto 50px; } }

.search-banner#FAQ .search-banner__content, .search-banner#downloads .search-banner__content {
  max-width: 900px;
  margin: 50px 5px; }
  @media screen and (min-width: 48rem) {
    .search-banner#FAQ .search-banner__content, .search-banner#downloads .search-banner__content {
      margin: 90px auto 100px; } }

.search-banner#FAQ .search-banner__heading, .search-banner#downloads .search-banner__heading {
  font-size: 26px;
  font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0;
  color: #2A2A2C; }
  @media screen and (min-width: 48rem) {
    .search-banner#FAQ .search-banner__heading, .search-banner#downloads .search-banner__heading {
      font-size: 48px; } }

.search-banner#FAQ .search-banner__search-form, .search-banner#downloads .search-banner__search-form {
  max-width: 620px;
  margin: 0 auto;
  position: relative; }
  .search-banner#FAQ .search-banner__search-form .form-input, .search-banner#downloads .search-banner__search-form .form-input {
    height: 60px;
    font-size: 18px !important;
    text-indent: 15px;
    background-color: #F8F8F9;
    border: 1px solid #D1D1D1; }

.search-banner#FAQ .search-banner__button, .search-banner#downloads .search-banner__button {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  border: none; }

.card-list--text-only#contact-us {
  background-color: #fff;
  padding-bottom: 60px; }
  .card-list--text-only#contact-us .card-list__title {
    font-size: 26px; }
    @media screen and (min-width: 48rem) {
      .card-list--text-only#contact-us .card-list__title {
        font-size: 42px; } }
  .card-list--text-only#contact-us .card-list__list {
    text-align: left;
    max-width: 1170px;
    justify-content: flex-start; }
    .card-list--text-only#contact-us .card-list__list .card {
      align-items: flex-start;
      padding: .5em 24px; }
      .card-list--text-only#contact-us .card-list__list .card--text-only {
        text-align: left;
        min-width: 317px;
        max-width: 360px; }
      .card-list--text-only#contact-us .card-list__list .card__title {
        font-size: 15px;
        font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0;
        margin-bottom: 10px;
        color: #2A2A2C; }
      .card-list--text-only#contact-us .card-list__list .card__subtitle p {
        font-size: 16px;
        padding-bottom: 5px; }
        @media screen and (min-width: 48rem) {
          .card-list--text-only#contact-us .card-list__list .card__subtitle p {
            font-size: 20px; } }

.content-container#about-us {
  padding: 50px 25px; }
  @media screen and (min-width: 48rem) {
    .content-container#about-us {
      padding: 80px 25px; } }
  .content-container#about-us .content-container__title {
    font-size: 1.25em;
    text-transform: uppercase;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    color: #2A2A2C;
    margin-bottom: 13px; }
    @media screen and (min-width: 48rem) {
      .content-container#about-us .content-container__title {
        font-size: 1.75em;
        margin-bottom: 32px; } }
  .content-container#about-us .content-container__content {
    font-size: 1.125em;
    color: #56565C;
    text-align: center;
    line-height: 1.625em; }
    @media screen and (min-width: 48rem) {
      .content-container#about-us .content-container__content {
        font-size: 1.25em;
        max-width: 900px; } }

.multi-cta.wasteking {
  box-shadow: none;
  margin: 3.5em auto;
  text-align: center; }
  .multi-cta.wasteking .text-box__head {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 1.625em; }
    @media screen and (min-width: 48rem) {
      .multi-cta.wasteking .text-box__head {
        font-size: 2em; } }

.multi-cta#about-us {
  margin: 0;
  padding: 45px 0;
  background-color: #F1F2F4; }
  @media screen and (min-width: 48rem) {
    .multi-cta#about-us {
      padding: 100px 0; } }
  .multi-cta#about-us .text-box__inner {
    background-color: #F1F2F4; }
  .multi-cta#about-us .text-box__head {
    display: block; }
    @media screen and (min-width: 48rem) {
      .multi-cta#about-us .text-box__head {
        display: none; } }
  .multi-cta#about-us .text-box__body {
    display: none; }
    @media screen and (min-width: 48rem) {
      .multi-cta#about-us .text-box__body {
        display: block;
        margin-bottom: 30px; }
        .multi-cta#about-us .text-box__body p {
          margin-bottom: 18px;
          color: #56565C;
          font-size: 32px; } }

.multi-cta#product-gallery {
  margin: 0 auto 3.5em; }
  .multi-cta#product-gallery .text-box {
    max-width: 800px;
    margin: 0 auto; }
    .multi-cta#product-gallery .text-box .text-box__head {
      font-size: 22px; }
      @media screen and (min-width: 48rem) {
        .multi-cta#product-gallery .text-box .text-box__head {
          font-size: 28px; } }

.multi-cta#product-detail {
  background-color: #F9F9FA;
  margin: 0 auto;
  padding: 0 25px 3.5em; }
  .multi-cta#product-detail .text-box {
    max-width: 780px;
    margin: 0 auto; }
    .multi-cta#product-detail .text-box__inner {
      background: transparent; }
    .multi-cta#product-detail .text-box__head {
      font-size: 22px; }
      @media screen and (min-width: 48rem) {
        .multi-cta#product-detail .text-box__head {
          font-size: 28px; } }

.multi-cta--horizontal {
  max-width: 1100px;
  margin: 0 auto;
  padding: .5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 50px; }
  @media screen and (min-width: 48rem) {
    .multi-cta--horizontal {
      flex-direction: row; } }
  .multi-cta--horizontal .multi-cta__item {
    max-width: 500px;
    margin: 0 auto !important; }
    .multi-cta--horizontal .multi-cta__item .media__head {
      font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      font-size: 20px;
      color: #2A2A2C; }
      @media screen and (min-width: 48rem) {
        .multi-cta--horizontal .multi-cta__item .media__head {
          font-size: 28px; } }
  @media screen and (min-width: body) and (min-width: 48rem) {
    .multi-cta--horizontal .multi-cta__item .media p {
      font-size: 20px; } }
    .multi-cta--horizontal .multi-cta__item .is-block .button--secondary {
      padding: 14px 6px; }
      @media screen and (min-width: 28rem) {
        .multi-cta--horizontal .multi-cta__item .is-block .button--secondary {
          padding: 14px 30px; } }

.multi-cta .text-box__body {
  line-height: 1.2em;
  font-size: 1.25em; }

.faq-list__category--level-1 {
  color: #fff; }
  .faq-list__category--level-1__item {
    background-color: #802428;
    border-right: 1px solid #641215; }
    .faq-list__category--level-1__item.is-selected {
      background-color: #641215; }
      .faq-list__category--level-1__item.is-selected:after {
        border-top: 8px solid #641215; }
    .faq-list__category--level-1__item .link {
      flex-direction: row;
      outline: none; }
  .faq-list__category--level-1.bleed:before {
    transform: translateX(-99%); }
  .faq-list__category--level-1.bleed:after {
    transform: translateX(99%); }
  .faq-list__category--level-1.desktop {
    display: none; }
    @media screen and (min-width: 48rem) {
      .faq-list__category--level-1.desktop {
        display: flex; } }
  .faq-list__category--level-1.mobile {
    display: flex; }
    @media screen and (min-width: 48rem) {
      .faq-list__category--level-1.mobile {
        display: none; } }

.faq-list__category--level-2 {
  margin: 40px 0; }
  .faq-list__category--level-2__item.is-selected {
    background-color: #641215; }
  .faq-list__category--level-2__item .link {
    color: #802428; }

.faq-list__collection .accordion__link {
  font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 15px;
  color: #2A2A2C;
  text-transform: uppercase;
  padding: 1.3em 1em 1em;
  background-color: #F8F8F9; }
  .faq-list__collection .accordion__link:hover, .faq-list__collection .accordion__link:focus {
    background-color: #EAEAEA; }
    .faq-list__collection .accordion__link:hover .sprite, .faq-list__collection .accordion__link:focus .sprite {
      background-position-y: 0px; }
  .faq-list__collection .accordion__link.is-selected .sprite {
    background-position-y: -293px; }
  .faq-list__collection .accordion__link .sprite {
    background-image: url("./images/icon-plus_minus_sprite_teal.png");
    width: 15px;
    height: 15px;
    background-size: cover; }
  .faq-list__collection .accordion__link.download {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    flex-direction: column;
    padding: 0;
    background: transparent; }
    @media screen and (min-width: 48rem) {
      .faq-list__collection .accordion__link.download {
        margin: 0; } }
    .faq-list__collection .accordion__link.download:hover .gtm_faq_text {
      background: #EAEAEA; }
    .faq-list__collection .accordion__link.download img {
      max-width: 300px; }
    .faq-list__collection .accordion__link.download .gtm_faq_text {
      margin: 0 auto;
      padding-top: 15px;
      padding: 15px 63px;
      background: #F8F8F9;
      width: 100%;
      max-width: 300px; }

.section-heading--1 {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  margin: 40px 15px; }
  @media screen and (min-width: 48rem) {
    .section-heading--1 {
      margin: 40px 0; } }

.section-heading--2 {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  margin: 40px 15px; }
  @media screen and (min-width: 48rem) {
    .section-heading--2 {
      margin: 40px 0; } }

.section-heading--3 {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  margin: 40px 15px; }
  @media screen and (min-width: 48rem) {
    .section-heading--3 {
      margin: 40px 0; } }

.faq__answer-helpful {
  font-weight: 400; }
  .faq__answer-helpful__count {
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 13px;
    text-transform: uppercase; }

.faq__answer-text {
  font-size: 16px; }
  @media screen and (min-width: 48rem) {
    .faq__answer-text {
      font-size: 20px; } }

.faq__feedback .helpful {
  font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.faq-subsubcat-title {
  margin-left: 15px; }
  @media screen and (min-width: 48rem) {
    .faq-subsubcat-title {
      margin-left: 0; } }

.link.wasteking {
  color: #802428; }

@media screen and (min-width: 48rem) {
  .downloads {
    padding: 0 20px;
    max-width: 1170px; } }

.downloads .gtm_faq_text {
  font-size: 13px;
  text-align: center; }
  @media screen and (min-width: 48rem) {
    .downloads .gtm_faq_text {
      font-size: 15px; } }

.downloads .faq-question-list {
  margin-bottom: 0; }

.downloads .faq__answer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media screen and (min-width: 48rem) {
    .downloads .faq__answer {
      padding: 0;
      justify-content: flex-start; } }
  .downloads .faq__answer .downloads__item {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: flex-start;
    width: 280px; }
    .downloads .faq__answer .downloads__item:last-child {
      margin-bottom: 0; }
    @media screen and (min-width: 48rem) {
      .downloads .faq__answer .downloads__item {
        margin: 25px 20px 60px; }
        .downloads .faq__answer .downloads__item:last-child {
          margin-bottom: 60px; } }
    @media screen and (min-width: 62rem) {
      .downloads .faq__answer .downloads__item {
        margin: 25px 45px 60px; } }
    .downloads .faq__answer .downloads__item li {
      list-style: none;
      padding-bottom: 18px; }
  .downloads .faq__answer .downloads__title {
    font-size: 20px;
    margin-bottom: 10px; }
    @media screen and (min-width: 48rem) {
      .downloads .faq__answer .downloads__title {
        font-size: 24px; } }
  .downloads .faq__answer .downloads__answer a {
    font-family: "Brandon-medium", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #427082; }
    .downloads .faq__answer .downloads__answer a img {
      margin-right: 10px;
      height: auto;
      width: 100%;
      max-height: 22px;
      max-width: 22px; }

.downloads #digital-catalog-subcat {
  width: 100%;
  max-width: 250px;
  margin: 0 auto; }
  @media screen and (min-width: 48rem) {
    .downloads #digital-catalog-subcat {
      margin: 0; } }
  .downloads #digital-catalog-subcat a {
    text-decoration: none;
    font-size: 13px;
    text-align: center;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 15px;
    color: #2a2a2c;
    text-transform: uppercase; }
    @media screen and (min-width: 48rem) {
      .downloads #digital-catalog-subcat a {
        font-size: 15px; } }
    .downloads #digital-catalog-subcat a:hover p {
      background: #eaeaea; }
  .downloads #digital-catalog-subcat img {
    max-width: 250px; }
  .downloads #digital-catalog-subcat p {
    margin: 0 auto;
    padding-top: 15px;
    padding: 15px 0px;
    background: #f8f8f9;
    width: 100%;
    max-width: 250px;
    font-weight: 800;
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

.downloads .section-heading--2 {
  font-size: 26px; }
  @media screen and (min-width: 48rem) {
    .downloads .section-heading--2 {
      font-size: 42px; } }

.downloads .faq-list__category--level-1__item.no-caret:after {
  border-top: none; }

.faq-list .small.no-size:before, .faq-list .small.no-size:after {
  content: ''; }

/*
Rich Text Component

Component allows for textual authoring and can accept a full-viewport-width color or an image as content background.


DEVELOPER NOTES:
The presence of a background-color and a background-image (single or repeating) is NOT mutually exclusive as indicated in the Jira ticket. Both style properties can be assigned to a single container.

Styleguide 4.25
*/
/*
Typographic

markup:
<section class="rtf-component">
  <div class="rtf-container">
    <h1>h1 - Base Typographic system for RTF</h1>
    <a href="">Click Me</a>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
      dolor in <b>reprehenderit in voluptate velit esse cillum</b> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat
      eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum gravida et. Integer lectus nisi, facilisis sit amet eleifend nec, pharetra ut augue. Integer quam nunc, consequat nec egestas ac,
      volutpat ac nisi. Sed consectetur dignissim dignissim. Donec pretium est sit amet ipsum fringilla feugiat. Aliquam erat volutpat. Maecenas scelerisque, orci sit amet cursus tincidunt, libero</p>
    <h2>Heading 2</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
      dolor in reprehenderit in voluptate velit esse</p>
    <h3>Heading 3</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
      dolor in</p>
    <ul>
      <li>Lorem ipsum dolor</li>
      <li>Sit amet, consectetur</li>
      <li>Adipisicing elit, sed</li>
      <li>Do eiusmod tempor</li>
      <li>Incididunt ut labore</li>
      <li>Et dolore magna</li>
      <li>Aliqua. Ut enim</li>
      <li>Ad minim veniam,</li>
      <li>Quis nostrud exercitation</li>
      <li>Ullamco laboris nisi</li>
    </ul>
    <h4>Heading 4</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    <hr />
    <h5>Heading 5</h5>
    <ol>
      <li>Coffee</li>
      <li>Tea</li>
      <li>Milk</li>
    </ol>
    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien
      consequat eleifend. </p>
    <p>Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum gravida et. Integer lectus nisi, facilisis sit amet eleifend nec, pharetra ut augue. Integer quam nunc, consequat nec egestas ac, volutpat ac
      nisi. </p>
    <h6>Heading 6</h6>
    <p>Sed consectetur dignissim dignissim. Donec pretium est sit amet ipsum fringilla feugiat. Aliquam erat volutpat. Maecenas scelerisque, orci sit amet cursus tincidunt, libero nisl eleifend tortor, vitae cursus risus mauris vitae nisi. Cras laoreet ultrices
      ligula eget tempus. </p>
    <p>Aenean metus purus, iaculis ut imperdiet eget, sodales et massa. Duis pellentesque nisl vel massa dapibus non lacinia velit volutpat. Maecenas accumsan interdum sodales. In hac habitasse platea dictumst. Pellentesque ornare blandit orci, eget tristique
      risus convallis ut. Vivamus a sapien neque. </p>
    <h6>Lorem 6 Dolor Sit</h6>
    <p>Nam quis sem orci. Phasellus ligula tellus, lobortis nec accumsan eget, mattis at erat. </p>
    <p>Pellentesque at odio quam, et egestas nunc. Sed quis leo et orci consectetur porttitor nec fringilla lorem. Duis sit amet adipiscing enim. </p>
  </div>
</section>

Styleguide 4.25.1
*/
.rtf-component {
  color: #56565C; }
  .rtf-component h1:not([class*="heading--"]) {
    font-size: 2.25em;
    color: #56565C;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    margin-bottom: 1rem;
    letter-spacing: -.05em; }
  .rtf-component h2:not([class*="heading--"]) {
    font-size: 1.75em;
    color: #56565C;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    letter-spacing: -.04em; }
  .rtf-component h3:not([class*="heading--"]) {
    font-size: 1.5em;
    color: #56565C;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    letter-spacing: -.03em; }
  .rtf-component h4:not([class*="heading--"]) {
    font-size: 1.25em;
    color: #56565C;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    letter-spacing: -.02em; }
  .rtf-component h5:not([class*="heading--"]) {
    font-size: 1.125em;
    color: #56565C;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    letter-spacing: -.01em; }
  .rtf-component h6:not([class*="heading--"]) {
    font-size: 1em;
    color: #56565C;
    padding: 0;
    margin: 0 0 .5rem 0;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }
  .rtf-component ul, .rtf-component ol {
    margin-bottom: 1em;
    margin-left: 1.2em; }
  .rtf-component hr {
    border-top: 2px solid #8b8d8e;
    margin: 0.5em 0 1em 0; }
  .rtf-component address {
    padding: 0 0 1em; }
  .rtf-component a.reversed {
    color: currentColor; }

/*
Background Image example 1 - Single image, no repeat

Uses a class modifier to express there is a single centered background image (non-repeating) assigned to the RTF component container.

markup:
<section class="rtf-component rtf-component--has-bg-image" style="background-color: #ededed; background-image: url(./images/styleguide/media-contact_background.png);">
  <div class="rtf-container">
    <h1>Media Contact Information</h1>
    <p>We are committed to offering accurate information and responding in a timely manner.
      <br> To help us serve you better, following is our list of key contacts.</p>
    <p>If you are a customer* and have questions regarding products, installation and/or
      <br>warranty information, please call our best-in-class-customer service department
      <br>at <b class="type-medium">(800) BUY–MOEN (1-800-289-6636)</b>.</p>
    <p>If you are a member of the media, please feel free to send your inquiries to:</p>
    <address>
					<b>Jennifer Allanson</b><br>
					Falls Communications<br>
					(216) 696-0229<br>
					<a href="mailto:jallanson@fallscommunications.com">jallanson@fallscommunications.com</a>
				</address>
    <address>
					<b>Kristi Stolarski</b><br>
					Falls Communications<br>
					(216) 696-0229<br>
					<a href="mailto:kstolarski@fallscommunications.com">kstolarski@fallscommunications.com</a>
				</address>
    <address>
					<b>Moen Media Team</b><br>
					<a href="mailto:media@moen.com">media@moen.com</a>
				</address>
  </div>
</section>

Styleguide 4.25.2
*/
.rtf-component--has-bg-image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover; }

/*
Background Image example 2 - Single image, no repeat

note: the reversed text is set here as inline styles in order to emulate the CK Editor.

note: we need to have a 'reversed' class which can be assigned to hyperlinks in order to override the default link color

markup:
<section class="rtf-component rtf-component--has-bg-image" style="background-color: #009FE8; background-image: url(./images/styleguide/ate-bg2.png);">
  <div class="rtf-container" style="color:#fff"> <!-- simulate CK Editor inline styles -->
      <h1>Ask The Expert</h1>
      <br />
      <br />
      <h2>Jack Suvak</h2>
      <br />
      <br />
      <p>Jack’s our master of consumer and industry insights. <br>
    Got a question for him? He’ll happily find your answer.</p>
    <br />
      <a class="reversed" href="/pressroom/ask-the-experts">Learn More</a>
  </div>
</section>

Styleguide 4.25.2.1
*/
/*
Background Image - Tiled image, repeating

Uses a class modifier to express there is a repeating background image (pattern) assigned to the RTF component container.

markup:
<section class="rtf-component rtf-component--has-repeating-image" style="background-image: url(./images/styleguide/bright_squares.png);">
  <div class="rtf-container">
    <h1>Lorem Ipsum Dolor Sit Amet C</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. </p>
  </div>
</section>

Styleguide 4.25.3
*/
.rtf-component--has-repeating-image {
  background-repeat: repeat;
  background-position: 0 0; }

#wasteking .page-title__h1 {
  font-family: "Brandon-medium", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

#wasteking .page-title__head {
  padding: 1em;
  justify-content: center; }

#wasteking .page-title__headings {
  align-items: center; }

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 48rem) {
    .hero-section {
      flex-direction: row;
      padding-bottom: 0; } }
  .hero-section__image .pdp-multimedia {
    display: none; }
  @media screen and (min-width: 48rem) {
    .hero-section__image {
      margin: 0 40px; }
      .hero-section__image .pdp-multimedia {
        display: none;
        padding-top: 20px;
        width: 100%;
        max-width: 1350px; }
        .hero-section__image .pdp-multimedia__button button {
          background-size: 50%;
          top: 100px; } }
  @media screen and (min-width: 62rem) {
    .hero-section__image {
      display: inline-block;
      width: 50%; } }
  .hero-section__image img {
    max-width: 400px;
    max-height: 400px; }
  .hero-section__description {
    margin: 0 auto 0 0;
    border-bottom: none; }
    @media screen and (min-width: 34rem) {
      .hero-section__description {
        margin: 0 auto; } }
    @media screen and (min-width: 62rem) {
      .hero-section__description {
        display: inline-block;
        width: 50%;
        transform: translate(0, -46%); }
        .wk-pdp-commercial-part .hero-section__description {
          transform: none; } }
  .hero-section__model {
    font-size: 13px;
    text-transform: uppercase;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    margin-bottom: -13px; }
    @media screen and (min-width: 48rem) {
      .hero-section__model {
        margin-bottom: -10px; } }
  .hero-section__text {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 32px; }
    @media screen and (min-width: 48rem) {
      .hero-section__text {
        font-size: 42px; } }
  .hero-section__buy {
    padding-bottom: 0; }
  .hero-section__buy-price {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: baseline; }
    .hero-section__buy-price span {
      font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      font-size: 24px;
      margin-right: 4px; }

.stickynav {
  display: flex; }
  .stickynav--red {
    background-color: #802428;
    color: #fff; }
    .stickynav--red .stickynav-menu__item {
      border-right: 1px solid #641215; }
      @media screen and (min-width: 48rem) {
        .stickynav--red .stickynav-menu__item:first-child {
          border-left: 1px solid #641215; } }
      .stickynav--red .stickynav-menu__item.is-selected:after {
        background-color: #641215; }

.features-section__title {
  text-align: center;
  margin: 0 auto;
  background: #56565C;
  color: #fff;
  padding: 35px 25px;
  font-size: 26px; }
  @media screen and (min-width: 48rem) {
    .features-section__title {
      font-size: 32px; } }

.features-section .gallery-cta {
  background-color: #fff;
  min-height: unset; }
  .features-section .gallery-cta__inner {
    max-width: unset;
    margin: 0 auto;
    padding: 0px 20px;
    display: block; }
  .features-section .gallery-cta__content {
    padding: 0; }
    @media screen and (min-width: 48rem) {
      .features-section .gallery-cta__content {
        padding: 30px 0 0; } }
    @media screen and (min-width: 62rem) {
      .features-section .gallery-cta__content {
        padding: 60px 0 0; } }
  .features-section .gallery-cta__link-container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    max-width: unset;
    flex-wrap: wrap;
    padding: 24px 0 0; }
    @media screen and (min-width: 48rem) {
      .features-section .gallery-cta__link-container {
        margin: 0;
        padding: 0; } }
    .features-section .gallery-cta__link-container .popup__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0 10px;
      flex-basis: 300px;
      position: relative; }
      .features-section .gallery-cta__link-container .popup__link.show:after {
        z-index: 9999;
        border: none;
        content: "◥";
        transform: rotate(-45deg);
        top: 55px;
        color: white;
        left: 6px;
        text-shadow: 2px -2px 2px #EAEAEA; }
      .features-section .gallery-cta__link-container .popup__link:last-child {
        padding-bottom: 0; }
      @media screen and (min-width: 28rem) {
        .features-section .gallery-cta__link-container .popup__link {
          flex-basis: 200px; }
          .features-section .gallery-cta__link-container .popup__link:last-child {
            padding-bottom: 10px; } }
      @media screen and (min-width: 62rem) {
        .features-section .gallery-cta__link-container .popup__link {
          padding: 0 0 20px;
          font-size: 14px; } }
    .features-section .gallery-cta__link-container .popup__description {
      padding: 0;
      font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
      font-size: 14px;
      color: #2A2A2C;
      text-transform: uppercase;
      margin-left: 15px; }
    .features-section .gallery-cta__link-container .popup__content {
      position: absolute; }
      .features-section .gallery-cta__link-container .popup__content.show {
        z-index: 2;
        top: 63px;
        left: -5px;
        box-shadow: 0px 0px 6px 2px #EAEAEA; }
        @media screen and (min-width: 28rem) {
          .features-section .gallery-cta__link-container .popup__content.show {
            left: -10px;
            width: 300px; } }
        @media screen and (min-width: 34rem) {
          .features-section .gallery-cta__link-container .popup__content.show {
            left: -15px;
            width: 330px; } }
        @media screen and (min-width: 62rem) {
          .features-section .gallery-cta__link-container .popup__content.show {
            left: -20px; } }

.features-section__heading {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 26px; }
  @media screen and (min-width: 48rem) {
    .features-section__heading {
      font-size: 32px; } }

.features-section__features-list {
  font-size: 16px; }
  @media screen and (min-width: 48rem) {
    .features-section__features-list {
      font-size: 20px; } }

.features-section .col-md-5 {
  background-color: #F1F2F4 !important; }

.features-section__label {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 26px;
  text-transform: capitalize; }
  @media screen and (min-width: 48rem) {
    .features-section__label {
      font-size: 32px; } }

.features-section .basic-specs dl dt {
  font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 11px;
  color: #9F9DA4;
  padding-bottom: 10px;
  text-transform: uppercase; }

.features-section .basic-specs dl dd {
  font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  color: #56565C;
  padding-bottom: 45px; }
  @media screen and (min-width: 48rem) {
    .features-section .basic-specs dl dd {
      font-size: 20px; } }

.features-section .basic-specs .link__icon--download {
  color: #427082; }
  .features-section .basic-specs .link__icon--download:before {
    background-image: url("./images/icon-download.svg");
    background-size: cover; }

.installation-support-section {
  background-color: #F9F9FA; }
  .installation-support-section.not-residential {
    padding: 0 0 2em; }
  .installation-support-section__title {
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 26px;
    padding: 50px 30px; }
    @media screen and (min-width: 48rem) {
      .installation-support-section__title {
        font-size: 32px;
        padding: 85px 25px 75px; } }
    .installation-support-section__title.not-residential {
      padding: 35px 25px;
      background-color: #56565C;
      color: white; }
  .installation-support-section__subtitle {
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 14px;
    text-align: center; }
    @media screen and (min-width: 62rem) {
      .installation-support-section__subtitle {
        text-align: left; } }
  .installation-support-section__inner {
    background: transparent !important; }
    .installation-support-section__inner--shrink {
      background: transparent; }
      .installation-support-section__inner--shrink.container {
        max-width: 1200px; }
      .installation-support-section__inner--shrink.installation-support-section__no-video {
        max-width: 1000px; }
        .installation-support-section__inner--shrink.installation-support-section__no-video .installation-support-section__fullwidth {
          max-width: 1000px; }
          .installation-support-section__inner--shrink.installation-support-section__no-video .installation-support-section__fullwidth .installation-support-section__subtitle {
            display: none; }
          .installation-support-section__inner--shrink.installation-support-section__no-video .installation-support-section__fullwidth .installation-support-section__support-list {
            display: flex;
            flex-wrap: wrap; }
            .installation-support-section__inner--shrink.installation-support-section__no-video .installation-support-section__fullwidth .installation-support-section__support-list .media {
              flex-wrap: nowrap;
              flex-basis: 100%;
              margin-bottom: 45px; }
              .installation-support-section__inner--shrink.installation-support-section__no-video .installation-support-section__fullwidth .installation-support-section__support-list .media:last-child {
                margin-bottom: 0; }
              @media screen and (min-width: 48rem) {
                .installation-support-section__inner--shrink.installation-support-section__no-video .installation-support-section__fullwidth .installation-support-section__support-list .media {
                  flex-basis: 50%;
                  padding: 0 .75em; } }
    .installation-support-section__inner.container {
      max-width: 1000px; }
  @media screen and (min-width: 62rem) {
    .installation-support-section__left {
      width: 50%; } }
  .installation-support-section__video-container {
    border: 1px solid #DBDBDB;
    border-top: 6px solid maroon;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    max-width: 547px; }
    @media screen and (min-width: 62rem) {
      .installation-support-section__video-container {
        max-width: unset; } }
  .installation-support-section__video-main-description {
    padding: 25px 30px 15px;
    font-size: 20px;
    text-overflow: initial;
    overflow: auto;
    white-space: normal; }
    @media screen and (min-width: 48rem) {
      .installation-support-section__video-main-description {
        font-size: 24px; } }
  .installation-support-section__video-main-link {
    padding: 0 30px 25px;
    font-size: 13px;
    font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    color: #802428;
    text-transform: uppercase;
    width: fit-content;
    position: relative; }
    .installation-support-section__video-main-link:after {
      content: "";
      width: 100%;
      height: 100%;
      background: url(images/icon-right-open_red.svg) no-repeat 50%;
      top: 1px;
      right: 10px;
      position: absolute;
      max-width: 9px;
      max-height: 13px; }
  .installation-support-section__right .installation-support-section__subtitle {
    padding-top: 20px; }
    @media screen and (min-width: 62rem) {
      .installation-support-section__right .installation-support-section__subtitle {
        padding-top: 0; } }
  @media screen and (min-width: 62rem) {
    .installation-support-section__right {
      width: 50%;
      padding-left: 100px; } }
  @media screen and (min-width: 48rem) {
    .installation-support-section__support-list .media__left {
      width: 8% !important;
      margin-right: 10px; } }
  .installation-support-section__support-list .media__head {
    font-size: 20px;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    padding-bottom: 10px; }
    @media screen and (min-width: 48rem) {
      .installation-support-section__support-list .media__head {
        font-size: 24px; } }
    .installation-support-section__support-list .media__head a {
      color: #427082; }
  .installation-support-section__support-list .media__body {
    font-size: 16px;
    padding-bottom: 35px; }
    .installation-support-section__support-list .media__body:last-child {
      padding-bottom: 0; }
    @media screen and (min-width: 48rem) {
      .installation-support-section__support-list .media__body {
        font-size: 20px; } }

.pdp-multimedia {
  display: block; }
  @media screen and (min-width: 48rem) {
    .pdp-multimedia {
      display: block; }
      .pdp-multimedia .pdp-multimedia__image,
      .pdp-multimedia .pdp-multimedia__video {
        max-height: 161px; } }

.videos__inner {
  max-width: 1300px;
  margin: 0 auto; }

.videos__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px; }

.videos .video {
  margin: 20px 0 35px;
  text-align: center;
  text-decoration: none; }
  @media screen and (min-width: 48rem) {
    .videos .video {
      margin-bottom: 55px; } }
  .videos .video:hover {
    text-decoration: none; }
  .videos .video:first-child {
    margin-top: 0px; }
  .videos .video:last-child {
    margin-bottom: 60px; }
    @media screen and (min-width: 48rem) {
      .videos .video:last-child {
        margin-bottom: 140px; } }
  .videos .video__image-container {
    position: relative; }
    .videos .video__image-container img {
      width: 100%;
      height: auto;
      max-width: 775px;
      max-height: 435px; }
  .videos .video__play-icon {
    position: absolute;
    background-image: url("./images/icon-play_outline_white.svg");
    height: 40px;
    width: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px); }
    @media screen and (min-width: 48rem) {
      .videos .video__play-icon {
        height: 95px;
        width: 95px;
        top: calc(50% - 45px);
        left: calc(50% - 45px); } }
  .videos .video__title {
    padding-top: 25px;
    font-size: 18px;
    font-family: "Brandon-regular", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    color: #2A2A2C; }
    @media screen and (min-width: 48rem) {
      .videos .video__title {
        padding-top: 35px;
        font-size: 24px; } }

.gallery-cta {
  background-color: #F1F2F4;
  min-height: 400px; }
  .gallery-cta__inner {
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 25px;
    display: block; }
    @media screen and (min-width: 48rem) {
      .gallery-cta__inner {
        padding: 0; } }
    @media screen and (min-width: 62rem) {
      .gallery-cta__inner {
        display: flex; } }
  .gallery-cta__image-container {
    display: none;
    width: 100%; }
    @media screen and (min-width: 48rem) {
      .gallery-cta__image-container {
        display: block; } }
  .gallery-cta__image-bg {
    background-size: cover;
    width: 100%;
    height: 530px;
    background-position: center; }
  .gallery-cta__content {
    padding: 0; }
    @media screen and (min-width: 48rem) {
      .gallery-cta__content {
        padding: 50px 75px; } }
    @media screen and (min-width: 62rem) {
      .gallery-cta__content {
        padding: 90px 75px 0; } }
  .gallery-cta__title {
    font-size: 26px;
    color: #2A2A2C;
    padding-bottom: 25px; }
    @media screen and (min-width: 48rem) {
      .gallery-cta__title {
        font-size: 32px; } }
  .gallery-cta__description {
    font-size: 16px;
    line-height: 1.5em; }
    @media screen and (min-width: 48rem) {
      .gallery-cta__description {
        font-size: 20px; } }
  .gallery-cta__link-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px 0 30px;
    max-width: 515px;
    margin: 0 auto; }
    @media screen and (min-width: 62rem) {
      .gallery-cta__link-container {
        margin: 0;
        max-width: 315px; } }
  .gallery-cta .popup__icon {
    width: 30px;
    height: 30px; }
  .gallery-cta .popup__link {
    position: relative; }
    .gallery-cta .popup__link .popup__icon--grey {
      display: block; }
    .gallery-cta .popup__link .popup__icon--teal {
      display: none; }
    .gallery-cta .popup__link:hover .popup__icon--grey {
      display: none; }
    .gallery-cta .popup__link:hover .popup__icon--teal {
      display: block; }
    .gallery-cta .popup__link.show:after {
      content: '';
      position: absolute;
      top: 30px;
      left: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: 15px solid #fff; }
  .gallery-cta .popup__content {
    display: none;
    position: relative;
    background: #fff;
    padding: 25px; }
    .gallery-cta .popup__content.show {
      display: block; }
  .gallery-cta .popup__title {
    font-family: "Brandon-bold", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px; }
    @media screen and (min-width: 48rem) {
      .gallery-cta .popup__title {
        font-size: 16px; } }
  .gallery-cta .popup__text {
    padding-bottom: 0; }

.product-videos {
  padding: 50px 25px; }
  @media screen and (min-width: 48rem) {
    .product-videos {
      padding: 100px 25px; } }
  .product-videos__inner {
    max-width: 800px;
    margin: 0 auto; }
  .product-videos__title {
    padding-bottom: 40px;
    font-size: 26px;
    text-align: center; }
    @media screen and (min-width: 48rem) {
      .product-videos__title {
        padding-bottom: 60px;
        font-size: 32px; } }
  .product-videos__container {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 48rem) {
      .product-videos__container {
        flex-direction: row; } }
    .product-videos__container .product-video {
      border: 1px solid #DBDBDB;
      border-top: 7px solid #802428;
      max-width: 380px;
      margin: 0 auto 30px; }
      @media screen and (min-width: 48rem) {
        .product-videos__container .product-video {
          margin: 0 10px; } }
      .product-videos__container .product-video__description {
        padding: 40px 30px 16px;
        font-size: 20px; }
        @media screen and (min-width: 48rem) {
          .product-videos__container .product-video__description {
            font-size: 24px; } }
      .product-videos__container .product-video__link {
        padding: 0 30px 0px;
        font-size: 13px;
        font-family: "Brandon-black", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        text-transform: uppercase;
        color: #802428;
        position: relative; }
        .product-videos__container .product-video__link:after {
          content: "";
          width: 100%;
          height: 100%;
          background: url(images/icon-right-open_red.svg) no-repeat 50%;
          top: 3px;
          right: 10px;
          position: absolute;
          max-width: 9px;
          max-height: 13px; }
        .product-videos__container .product-video__link:hover {
          text-decoration: none; }
      .product-videos__container .product-video__image-bg {
        background-size: cover;
        height: 185px;
        margin-top: 30px; }

.container--main {
  margin: 1em auto 0; }

.plmr-u-bg--brand-primary {
  background-color: #802428; }

.plmr-u-bg--background-primary {
  background-color: #ffffff; }

.plmr-c-general-content,
.plmr-c-general-content strong,
.plmr-c-content-area__title,
.plmr-c-form-label,
.plmr-c-form-text--helper {
  font-family: inherit; }
