@include block('pdp-multimedia'){
  display: block;
  @include media($bp-md){
    display: block;

    .pdp-multimedia__image,
    .pdp-multimedia__video{
      max-height: 161px;
    }
  }
}
