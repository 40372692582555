

body {
  color: $c_wk_body_text-base;
  font-family: $brandon_regular;
  line-height: 1.2;
}
  
  
a {
  text-decoration: none;
  
  
  &:hover {
    text-decoration: underline;
  }
}

ul {
  font-family: $brandon_regular;
}