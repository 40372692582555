/// using simple white boxes variant
// .card-list
@include block('card-list'){
  max-width: none;
  // .card-list--simple-white-boxes
  @include modifier('simple-white-boxes'){
    background-color: $c_wk_card-list_background-color;
    padding: 45px 22px 60px;

    // screens > 768
    @include media($bp-md){
      padding: 55px 20px 95px;
    }

    //adding this so BEM Mixins can be used
    .card-list{
      // .card-list__title
      @include element('title'){
        font-size: 26px;
        margin-top: 0px;

        // screens > 768
        @include media($bp-md){
          font-size: 42px;
        }
      }
      // .card-list__heading
      @include element('heading'){
        margin-bottom: 0;

        // .card-list__heading p
        p{
          font-size: 16px;
          padding-bottom: 0;
          letter-spacing:0px;

          // screens > 768
          @include media($bp-md){
            font-size: 20px;
          }
        }
        
      }
      // .card-list__list
      @include element('list'){
        padding-top: 30px;
        
        //screens > 768
        @include media($bp-md){
          padding-top: 60px;
        }

        // .card-list .card
        @include block('card'){
          min-width: 328px;
          min-height: 300px;
          max-width: 380px;
          max-height: 306px;
          
          //screen > 768
          @include media($bp-md){
            margin: 0 10px ;

            // .card-list .card:first-child
            &:first-child{
              margin-left: 0;
            }
            // .card-list .card:last-child
            &last-child{
              margin-right: 0;
            }
          }

          // .card-list .card--simple-white-box
          @include modifier('simple-white-box'){
            box-shadow: none;
            border: 1px solid $c_wk_card-list_border-color;
            min-height: 300px;
            padding: 50px 30px 40px;
            margin-bottom: 30px;

            // screens > 768
            @include media($bp-md){
              padding: 55px 40px 45px;
              margin-bottom: 20px;
            }
          }

          // .card-list .card__image
          @include element('image'){
            width: 100px;
          }
          // .card-list .card__title
          @include element('title'){
            font-size: 15px;
            text-transform: uppercase;
            color: $c_wk_card-list_title-color;
            font-family: $brandon_bold;
            letter-spacing: 1px;
          }
          // .card-list .card__subtitle
          @include element('subtitle'){
            // .card-list .card__subtitle p
            p{           
              font-size: 16px;
              padding-bottom: 0;
              
              //screens > 768
              @include media($bp-md){
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  // .card-list__title
  @include element('title'){
    font-size: 1.75em;
  }
  @include child('heading'){
    font-family: $brandon_bold!important;
  }
  .card.card--simple-white-box{
    justify-content: space-between;
  }

  
}

.card--top-blue-border {
    border-top: 4px solid $c_wk-maroon;
    height: 340px;
    min-height: 340px;
    padding: 0;
    margin-bottom: 1.25em;
    transition: height .3s,box-shadow .3s;
}

.card--top-blue-border .card__title {
    color: $c_wk-maroon;
    font-size: 1.625em;
    font-family: DINWebLight,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    margin: 0 0 .625em;
    text-align: center;
}

.link {
    color: $c_wk-maroon;
}

.card--top-blue-border .pointer-item.is-selected:after {
    border-top-color: $c_wk-maroon;
}


