@include block('stickynav'){
  display: flex;

  @include modifier('red') {
    background-color: $c_wk-maroon;
    color: $c_white;
    .stickynav-menu__item{
      border-right: 1px solid $c_wk-dark-maroon;

      &:first-child{
        @include media($bp-md){
          border-left: 1px solid $c_wk-dark-maroon;
        } 
      }

      &.is-selected{
        &:after{
          background-color: $c_wk-dark-maroon;
        }
      }
    }
  }
}