@include block('faq-list'){
  @include element('category'){
    @include modifier('level-1'){
      color: $c_white;
      @include element('item'){
        background-color: $c_wk-maroon;
        border-right: 1px solid $c_wk-dark-maroon;

        &.is-selected{
          background-color: $c_wk-dark-maroon;
          &:after{
            border-top: 8px solid $c_wk-dark-maroon;
          }
        }

        .link{
          flex-direction: row;
          outline: none;
        }
      }

      &.bleed:before{
        transform: translateX(-99%);
      }
      &.bleed:after{
        transform: translateX(99%);
      }

      &.desktop{
        display: none;
        @include media($bp-md){
          display: flex;
        }
      }

      &.mobile{
        display: flex;
        @include media($bp-md){
          display: none;
        }
      }
    }
    @include modifier('level-2'){
      margin: 40px 0;

      @include element('item'){
        &.is-selected{
          background-color:$c_wk-dark-maroon;
        }
        .link{
          color: $c_wk-maroon;
        }
      }
    }
    
  }
  @include element('collection'){
    @include block('accordion'){
      @include element('link'){
        font-family: $brandon_bold;
        font-size: 15px;
        color: $c_wk_heading_text-base;
        text-transform: uppercase;
        padding: 1.3em 1em 1em;
        background-color: $c_wk_faq_question-background-color;

        &:hover,
        &:focus{
          background-color: #EAEAEA;

          .sprite{
            background-position-y: 0px;
          }
        }

        &.is-selected{
          .sprite{
            background-position-y: -293px;
          }
        }

        .sprite{
          background-image:url('./images/icon-plus_minus_sprite_teal.png');
          width: 15px;
          height: 15px;
          background-size: cover;
        }

        &.download{
          width: 100%;
          max-width: 300px;
          margin: 0 auto;
          flex-direction: column;
          padding: 0;
          background: transparent;

          @include media($bp-md){
            margin: 0;
          }
          &:hover{
            .gtm_faq_text{
              background: #EAEAEA;
            }
          }
          img{
            max-width: 300px;
          } 
         .gtm_faq_text{
            margin: 0 auto;
            padding-top: 15px;
            padding: 15px 63px;
            background: $c_wk_lighest-grey;
            width: 100%;
            max-width: 300px;
          }
        }
      }
    }
  }

}

@include block('section-heading'){
  @include modifier(1){
    font-family: $brandon_regular;
    margin: 40px 15px;

    @include media($bp-md){
      margin: 40px 0;
    }
  }
  @include modifier(2){
    font-family: $brandon_regular;
    margin: 40px 15px;

    @include media($bp-md){
      margin: 40px 0;
    }
  }
  @include modifier(3){
    font-family: $brandon_regular;
    margin: 40px 15px;

    @include media($bp-md){
      margin: 40px 0;
    }
  }
}


@include block('faq'){
  @include element('answer-helpful'){
    font-weight: 400;
    
    @include element('count'){
      font-family: $brandon_bold;
      font-size: 13px;
      text-transform: uppercase;
    }
  }
  @include element('answer-text'){
    font-size: 16px;
    @include media($bp-md){
      font-size: 20px;
    }
  }
  @include element('feedback'){
    .helpful{
      font-family: $brandon_bold;
    }
  }
}

@include block('faq-subsubcat-title'){
  margin-left: 15px;

  @include media($bp-md){
    margin-left: 0;
  }
}

.link.wasteking{
  color: $c_wk-maroon;
}