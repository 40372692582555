/// .homepage
@include block('wasteking'){

  /// .content-container
  @include block('content-container'){

    /// .content-container__content
    @include element('content'){
      text-align: left;
      h2{
        margin-bottom: 1.5em;
        text-align: center;
      }

    }
  }
}