/// .header-container
@include block('header-container') {
  flex-direction: row;
  flex-wrap: wrap;
  
  /// screen > 768px
  @include media($bp-md) {
    flex-wrap: nowrap;
  }

} 