/// Mostly styling for banner content left and right styling

/// .banner-article
@include block('banner-article'){
  margin: 0 auto;
  max-width:unset;

  /// .banner-article__inner
  @include element('inner'){
    max-width: 1300px;
    width: 100%;
    display: flex;
    margin: 0 auto;
  }

  /// .banner-article__image-container
  @include element('image-container'){
    display: none;

    /// screens > 768px
    @include media($bp-md){
      display: block;
    }
  }

  /// .banner-article--content-right
  @include modifier('content-right'){
    .banner-article__inner{
      flex-direction: row;

      .banner-article__content{
        padding: 25px;
        @include media($bp-md){
          padding: 75px 60px;
        }
        @include media($bp-xl){
          padding:75px 0px 75px 60px;
        }
      }
    }

  }

  /// .banner-article--content-left
  @include modifier('content-left'){
    .banner-article__inner{
      flex-direction: row-reverse;

      .banner-article__content{
        padding: 25px;
        @include media($bp-md){
          padding: 75px 60px;
        }
        @include media($bp-xl){
          padding:75px 60px 75px 0px;
        }
      }
    }
  }


  /// .banner-article__content
  @include element('content'){
    text-align: left;
  }

  /// .banner-article__title
  @include element('title'){
    font-size: em(18);
    font-family: $brandon_bold;
    text-transform: uppercase;

    @include media($bp-md){
      font-size: em(24);
    }

  }
  /// .banner-article__title-link
  @include element('title-link'){
    color: $c_black;
    &:hover{
      text-decoration: none;
    }
  }

  /// .banner-article__subtitle
  @include element('subtitle'){
    font-family: $brandon_regular;
  }
  /// .banner-article__description
  @include element('description'){
    font-family: $brandon_regular;
  }
  /// .banner-article__link
  @include element('link'){
    font-family: $brandon_black;
    color: $c_wk-maroon;
    font-size: em(13);
    text-transform: uppercase;
    &:hover{
      text-decoration: none;
    }
  }

}

#about-us-even,
#about-us-odd{
  @include block('banner-article'){

    @include element('content'){
      padding: 50px 25px;

    }

    @include element('title'){
      font-size: em(32);
      text-transform: capitalize;
      font-family: $brandon_regular;
      margin-bottom:25px;


      @include media($bp-md){
        font-size: em(42);
      }
    }
    @include element('title-link'){
      color:$c_wk_body_text-base;
    }

    @include element('description'){
      p{ 
        color: $c_wk_body_text-base;
        font-size: 16px;
        margin-bottom: 25px;
        padding-bottom: 0;

        @include media($bp-md){
          font-size: 20px;
        }
      }
    }
  }
}

#about-us-odd{
  background-color:$c_wk-banner-content-left_background;
}

#product{

}


.banner-article__description, .banner-article__subtitle {
    font-family: Brandon-regular,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 1.25em;
}