/// .header-main
@include block('header-main'){
  border-bottom:none;
}


/// .header-logo
@include block('header-logo'){
    margin: 0 0 0 20px;
    @include element('image'){
      height:20px;
      width: 166px;
    }
}