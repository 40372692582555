/// .main-nav
@include block('main-nav'){
  

  /// .main-nav__link
  @include element('link'){
    color: $c_wk-med-grey;
    text-transform: uppercase;
    font-family: $brandon_black;
    font-size: em(14);
    letter-spacing:0.1em;

    /// .main-nav__link.hover, .main-nav__link.focus, .main-nav__link.open
    &.focus,
    &.hover,
    &.open{
      color: $c_wk-maroon;

      &:after {
        content:'';
        width: 0; 
        height: 0; 
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid $c_wk-lighter-grey;
        position: absolute;
        bottom: -1px;
        left: calc(50% - 15px);
        z-index:100;
      }
    }

    
  }

  /// .main-nav__flyout
  @include element('flyout'){
    background-color: $c_wk-lighter-grey;
    box-shadow: 0 4px 6px -5px $c_moen-gray;
    top: 100%;
  }
}



/// .main-sub-nav
@include block('main-sub-nav'){
  @include element('list'){
    justify-content: flex-start;
  }

  @include element('item'){
    margin: 15px 30px;
  }

  @include element('link'){
    text-align: left;
    margin: 15px 0;
    font-family: $brandon_regular;
    width: fit-content;


    .link-image-menu__label{
      text-transform: capitalize;
      
      &:after{        
        content: "";
        width: 13px;
        height: 13px;
        background:url("./images/icon-right-open_red.svg") no-repeat center;
        top: 2px;
        right: -20px;
        position: absolute;
        display: none;
      }

      &:hover,
      &:focus {
        color: $c_wk-maroon;
        position: relative;

        &:after{        
          display: inline-block;
        }
        
      }

      
    }
  
    .submenu-header {
      font-weight: bold;
      text-transform: uppercase;
      &:after{        
        top:-1px;
      }
    }
  }
}