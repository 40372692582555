/*
 *  http://thoughtbot.github.io/neat-docs/latest/
 *  http://culttt.com/2013/12/09/adding-bourbon-and-bourbon-neat-with-bower/
 *
 *  Grid functionality is defined with mixins and functions in this file; defaults are stored in the settings/neat-grid
 *
 */

/*
Grid

Grid is built using Bourbon Neat, using the familiar Bootstrap 4 class naming conventions.

Styleguide 10
*/


/*
Base Grid Example

This is just an example to make sure the build works with Bourbon Neat.

markup:
<div class="container">
<div class="row">
  <div class="child col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">1</div>
  <div class="child col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">2</div>
</div>
<div class="row">
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">3</div>
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">4</div>
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">5</div>
  <div class="child col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">6</div>
</div>
<div class="row">
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">3</div>
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">4</div>
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">5</div>
  <div class="child xxs--bottom-margin col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">6</div>
</div>
<div class="row">
  <div class="child col-xl-1 col-lg-2 col-md-3 col-sm-2 col-xs-3">7</div>
  <div class="child col-xl-2 col-lg-4 col-md-3 col-sm-5 col-xs-4">8</div>
  <div class="child col-xl-3 col-lg-4 col-md-3 col-sm-2 col-xs-3">9</div>
  <div class="child col-xl-6 col-lg-2 col-md-3 col-sm-3 col-xs-2">10</div>
</div>
</div>

Styleguide 10.1
*/

// this is for styleguide demo:
.child {
  padding: 1em;
  font-weight: bold;
  height: 200px;
  background-color: $c_white;
  border: 1px solid $c_moen-gray;
  margin-bottom: $grid-gutter-width;
  padding: 1rem;
}

// Rows, contained within an outer container
.row {
  @include row();
}

@mixin build-grid() {
  @each $name, $width in $grid-breakpoints {
    @media (min-width: $width) {
      @for $i from 1 through $grid-num-cols {
        // span-columns() is a neat.bourbon.io function to generate grid width
        .col-#{$name}-#{$i} {
          @include span-columns($i);
          padding: 0;
        }
        // shift() is a neat.bourbon.io function to span grid columns
        .col-#{$name}-push-#{$i} {
          @include shift($i);
        }
        .col-#{$name}-pull-#{$i} {
          @include shift(0-$i);
        }
      }

      // omega() is a neat.bourbon.io function to remove right margin
      .#{$name}--omega {
        @include omega();
      }

      // // Hide element based on breakpoint
      // .#{$name}--hidden {
      //   display: none;
      // }
      //
      // .#{$name}--display {
      //   display: block;
      // }

      // bottom margin
      .#{$name}--bottom-margin {
        margin-bottom: 2em;
      }

      // top margin
      .#{$name}--top-margin {
        margin-top: 2em;
      }

      // Breakpoint float left
      .#{$name}--float-left {
        float: left;
      }

      // Breakpoint float right
      .#{$name}--float-right {
        float: right;
      }

      // Breakpoint
      .#{$name}--text-center {
        text-align: center;
      }

      // Breakpoint
      .#{$name}--text-left {
        text-align: left;
      }

      // Breakpoint
      .#{$name}--text-right {
        text-align: right;
      }

    }
  }
}

// .flex-row {
//   box-sizing: border-box;
//   display: flex;
//   flex: 0 1 auto;
//   flex-direction: row;
//   flex-wrap: wrap;
// }
// 
// 
// @mixin build-flex-grid() {
//   @each $name, $width in $grid-breakpoints {
//     @media (min-width: $width) {
//       
//       
//       .flex-col-#{$name} {
//         box-sizing: border-box;
//         flex: 0 0 auto;
//         flex-grow: 1;
//         flex-basis: 0;
//         max-width: 100%; 
//         padding: 0 2.35765%;
//       }
//       
//       @for $i from 1 through $grid-num-cols {
//       
//         $column-width: percentage($i / $grid-num-cols); 
//         .flex-col-#{$name}-#{$i} {
//           flex-basis: $column-width;
//           max-width: $column-width;
//         }        
//       }
//     }
//   }
// }


// build grid classes
@include build-grid();


// build flex grid classes
// @include build-flex-grid();
