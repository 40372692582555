/// Mostly styling for Knockout Banner variant

/// .wasteking
@include block('wasteking'){

  /// .banner
  @include block('banner'){

    /// .banner__image-bg
    @include element('image-bg'){
      width: 100% !important;
      height: 100% !important;
      max-width: initial !important;

      /// screens > 768
      @include media($bp-md){
        width:100%;
        height: 100%;
      }

      /// screen > 992px
      @include media($bp-lg){
        width: 100%;
        height: 100%;
      }
    }

    /// .text-box{
    @include block('text-box'){

      /// .text-box__subtitle
      @include element('subtitle'){
        font-size: em(26)!important;
        @include media($bp-md){
          font-size: em(48)!important;
        }
      }
      /// .text-box__body
      @include element('body'){
        font-size: em(18);
        @include media($bp-md){
          font-size: em(20);
        }
      }
    }


    /// .banner-text-brandon
    @include block('banner-text-brandon') {

      /// .banner-text-brandon__bold
      @include child('bold') {
        font-family: $brandon-bold;
        text-transform: uppercase;
      }
      
      /// .banner-text-brandon
      @include child('light') {
        font-family: $brandon-light;
      }
      
    }


  }

  &#about-us{
    height: auto;
    
    .bleed {
      &:before,
      &:after{
        height: 93.1%;
      }
      &::before{
        transform: translateX(-99%);
      }
    }
    .banner{
      height: 290px;

      @include media($bp-md){
        height: 400px;
      }
      @include media($bp-lg){
        height:489px;
      }
    
    }
  }
}
